import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CheckoutService from "../../auth/CheckoutService";
import { GetCheckoutSessionResponse, GetCheckoutSessionResponseCode } from "../../stripeTypes";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import ContentService from "../../content-service/ContentService";
import { userActions } from "../../storage/UserSlice";

const CheckoutStatusPage = () => {

    const { sessionID } = useParams<{ sessionID: string }>();
    const [session, setSession]: [GetCheckoutSessionResponse | null, any] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {

        const fetchCheckoutSession = async () => {
            if(!sessionID) return;

            const sesh: GetCheckoutSessionResponse = await CheckoutService.getCheckoutSession(sessionID);
            setSession(sesh);

            if(sesh.code === GetCheckoutSessionResponseCode.SUCCESS) {
                await ContentService.refreshUser();
                setTimeout(() => {
                    navigate('/app/my-content/projects', { replace: true });
                }, 4000);
            }
        }

        fetchCheckoutSession();

    }, [sessionID]);

    return (
        <div>
                {session && (session as GetCheckoutSessionResponse).code === GetCheckoutSessionResponseCode.SUCCESS &&
                    (
                        <div className="flex flex-col justify-center items-center h-screen">
                            <CheckBadgeIcon className="w-20 h-20 text-green-500" />
                            <p>Success! Your credits have been added to your account, you will now
                                be redirected to your projects page.
                            </p>
                        </div>
                    )
                }

                {session && (session as GetCheckoutSessionResponse).code === GetCheckoutSessionResponseCode.CARD_ERROR &&
                    (
                        
                        <p>There was an error processing your card. Please try again.</p>
                    )
                }

                {session && (session as GetCheckoutSessionResponse).code === GetCheckoutSessionResponseCode.IDEMPOTENCY_ERROR &&
                    (
                        <p>There was an error processing your card. Please try again.</p>
                    )
                }

                {session && (session as GetCheckoutSessionResponse).code === GetCheckoutSessionResponseCode.INVALID_SESSION_ERROR &&
                    (
                        <p>There was an error processing your card. Please try again.</p>
                    )
                }

                {session && (session as GetCheckoutSessionResponse).code === GetCheckoutSessionResponseCode.API_ERROR &&
                    (
                        <p>There was an error processing your card. Please try again.</p>
                    )
                }
        </div>
    );

}

export default CheckoutStatusPage;