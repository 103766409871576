import { useCallback, useState } from "react";
import CreateModal from "../../components/CreateModal";
import GradientBorderButton from "../../components/GradientBorderButton";
import List from "../../components/List";
import { useSelector } from "react-redux";
import ParaphrasingService from "../../content-service/ParaphrasingService";
import { useNavigate } from "react-router-dom";


const ParaphrasePage: React.FC = () => {

    const [showCreateFileModal, setShowCreateFileModal] = useState<boolean>(false);

    const navigate = useNavigate();

    const paraphrasingFiles = useSelector((state: any) => state.paraphrasingFiles);

    const createFile = useCallback(async(title: string) => {
        console.log('creating file', title);
        await ParaphrasingService.addParaphrasingFile({ title: title });
        return true;
    }, []);

    const navigateToFile = useCallback((fileID: string) => {
        navigate(`/app/my-content/paraphrasing/${fileID}`);
    }, [paraphrasingFiles]);

    return (
        <div id="paraphrase-container" className="px-4 sm:px-4 md:px-4 lg:px-6">
            <CreateModal
                isOpen={showCreateFileModal} 
                toggleModal={() => setShowCreateFileModal(!showCreateFileModal)} 
                onSubmit={createFile}
                type="paraphrase"
            />
            <div id="projects-header" className="mb-6">
                <div id="title" className="flex justify-start font-bold text-3xl mb-9">
                    Paraphrasing
                </div>
            </div>
            <GradientBorderButton
                onClick={() => setShowCreateFileModal(!showCreateFileModal)}
            >
                New File
            </GradientBorderButton>
            <List 
                type="paraphrase"
                fieldNames={['title']}
                items={paraphrasingFiles}
                onRowPress={navigateToFile}
            />
        </div>
    );
}

export default ParaphrasePage;