import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ParaphrasingFile } from "../globalTypes";

export const ParaphrasingSlice = createSlice({
    name: 'paraphrasingFiles',
    initialState: {},
    reducers: {
        setParaphrasingFiles: (state, action) => {
            return action.payload
        },
        addParaphrasingFile: (state: any, action: PayloadAction<{
            fileID: string,
            title: string,
            targetWordCount: number
        }>) => {
            state[action.payload.fileID] = {
                title: action.payload.title,
                targetWordCount: action.payload.targetWordCount
            }
        },
        updateParaphrasingFile: (state: any, action: PayloadAction<{
            fileID: string,
            properties: Partial<ParaphrasingFile>
        }>) => {
            console.log('current state', state[action.payload.fileID]);
            console.log('action payload', action.payload)
            for(const property in action.payload.properties) {
                // @ts-ignore
                state[action.payload.fileID][property] = action.payload.properties[property];
            }
        },
        deleteParaphrasingFile: (state: any, action: PayloadAction<{
            fileID: string
        }>) => {
            delete state[action.payload.fileID];
        }
    },
});

export const paraphrasingActions = {
    ...ParaphrasingSlice.actions
};
export default ParaphrasingSlice.reducer;