import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { SearchBarProps } from "../../globalTypes";
import useColors from "../../hooks/useColors";

const SearchBar = (props: SearchBarProps) => {
    const colors = useColors();
    return (
        <div 
            className={`flex items-center rounded-[8px] p-[8px]`}
            style={{background: colors.secondaryBG}}
        >
            <MagnifyingGlassIcon className={`h-5 w-5 mr-2`} style={{stroke: colors.icon}} />
            <input 
                type="text" 
                placeholder="Search..." 
                style={{
                    background: 'transparent',
                    border: 'none',
                    outline: 'none',
                    color: 'white',
                    width: '100%',
                }}
            />
        </div>
    )
}

export default SearchBar;