import { Dialog, Transition } from "@headlessui/react"
import React, { Fragment, useEffect, useState } from "react";
import useColors from "../hooks/useColors";
import { TrashIcon, XCircleIcon } from "@heroicons/react/24/outline";

type ConfirmModalProps = {
    isOpen: boolean,
    toggleModal: any,
    onSubmit: any,
    title: React.ReactNode,
    message: React.ReactNode,
    submitText: string,
    submitColor?: string,
    modalIcon?: any
}

const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {

    const colors = useColors();

    function confirm() {
        props.onSubmit();
        props.toggleModal();
    }

    function cancel() {
        props.toggleModal();
    }

    return (
        <Transition appear show={props.isOpen} as={Fragment}>
            <Dialog className="z-10" open={props.isOpen} onClose={() => props.toggleModal()}>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black opacity-30" />
                </Transition.Child>

                <Dialog.Panel 
                    className="
                        w-full
                        absolute 
                        bottom-0 
                        transform 
                        overflow-hidden 
                        rounded-2xl 
                        text-left 
                        align-middle 
                        shadow-xl 
                        transition-all 
                        md:w-1/2 md:h-max md:m-auto md:top-0 md:bottom-0 md:left-0 md:right-0
                        lg:w-1/2 lg:h-max md:m-auto md:top-0 md:bottom-0 md:left-0 md:right-0
                    "
                    style={{background: colors.secondaryBG}}
                >
                    <Dialog.Title 
                        className="flex justify-between items-center p-4"
                    >
                        <div className="flex items-center">
                            <div className="h-8 w-8 text-indigo-500 mr-2">
                                {props.modalIcon ? <props.modalIcon/> : <TrashIcon />}
                            </div>
                            <p>
                                {props.title}
                            </p>
                        </div>
                        <XCircleIcon 
                            className="h-6 w-6 text-gray-400 hover:text-gray-700 cursor-pointer" 
                            onClick={() => cancel()} 
                        />
                    </Dialog.Title>
                    <Dialog.Description className="p-3">
                        {props.message}
                    </Dialog.Description>
            
                    <div className="flex justify-end pr-2 pb-2">
                        <button 
                            className="bg-gray-200 text-gray-700 p-2 rounded hover:bg-gray-300 mr-2 cursor-pointer"
                            onClick={() => cancel()}
                        >
                            Cancel
                        </button>
                        <button 
                            onClick={() => confirm()}
                            className="text-white p-2 rounded hover:bg-red-700 cursor-pointer"
                            style={{
                                background: props.submitColor ? props.submitColor : colors.primaryBG
                            }}
                        >
                            {props.submitText}
                        </button>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </Transition>
    )
}

export default ConfirmModal;
