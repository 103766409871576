import { configureStore } from '@reduxjs/toolkit';
import ProjectsSlice from './ProjectsSlice';

import RecentlyViewedSlice from './RecentlyViewedSlice';
import UserSlice from './UserSlice';
import ParaphrasingSlice from './ParaphrasingSlice';

export default configureStore({
  reducer: {
    projects: ProjectsSlice,
    recentlyViewed: RecentlyViewedSlice,
    user: UserSlice,
    paraphrasingFiles: ParaphrasingSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});