import LoginService from "../auth/LoginService";
import { ParaphrasingFile } from "../globalTypes";
import { paraphrasingActions } from "../storage/ParaphrasingSlice";
import store from "../storage/store";
import { userActions } from "../storage/UserSlice";

type AddParaphrasingFileParams = {
    title: string,
}

type UpdateParaphrasingFileParams = {
    fileID: string,
    properties: Partial<ParaphrasingFile>
}

type DeleteParaphrasingParams = {
    fileID: string,
}

function getUniqueTitle(newTitle: string, existingTitles: string[]) {
    if (!existingTitles.includes(newTitle)) {
        return newTitle; // Return the title if it's not in the array
    }

    let counter = 1;
    let modifiedTitle;

    // Loop until a unique title is found
    do {
        modifiedTitle = `${newTitle} (${counter})`;
        counter++;
    } while (existingTitles.includes(modifiedTitle));

    return modifiedTitle; // Return the modified title
}

const ParaphrasingService = {
    fetchParaphrasingFiles: async() => {
        try {
            const userSession = await LoginService.getUserSession();
            if(userSession) {
                const user = await ParaphrasingService.getUser();
                return user.paraphrasingFiles;
            } 
        } catch(e) {
            console.log('error fetching projects', e);
        }
    },
    addParaphrasingFile: async(params: AddParaphrasingFileParams) => {

        const userSession = await LoginService.getUserSession();
        const url = 'https://myok4hinbg.execute-api.us-east-2.amazonaws.com/createParaphrasingFile';

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': userSession.getIdToken().getJwtToken(),
            },
            body: JSON.stringify({
                title: params.title,
            }),
        });
        
        const newFile: {
            fileID: string,
            title: string,
            targetWordCount: number,
            updatedAt: string,
            createdAt: string,
        } = await response.json();
        
        store.dispatch(paraphrasingActions.addParaphrasingFile(newFile));
    },
    updateParaphrasingFile: async(params: UpdateParaphrasingFileParams) => {
        try {
            if(params.properties.title) {
                const existingTitles = Object.entries(store.getState().paraphrasingFiles).map(([fileID, paraphrasingFile]: any) => {
                    return paraphrasingFile.title;
                });
                params.properties.title = getUniqueTitle(params.properties.title, existingTitles);
            }
            store.dispatch(paraphrasingActions.updateParaphrasingFile(params));
            const userSession = await LoginService.getUserSession();
            const url = 'https://myok4hinbg.execute-api.us-east-2.amazonaws.com/updateParaphrasingFile';

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userSession.getIdToken().getJwtToken(),
                },
                body: JSON.stringify(params),
            });

            const updateResponse = await response.json();
            return updateResponse;
        } catch(e) {
            console.log('error updating project', e);
        }
    },
    deleteParaphrasingFile: async(params: DeleteParaphrasingParams) => {
        try {
            const userSession = await LoginService.getUserSession();
            const url = 'https://myok4hinbg.execute-api.us-east-2.amazonaws.com/deleteParaphrasingFile';

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userSession.getIdToken().getJwtToken(),
                },
                body: JSON.stringify(params),
            });

            const deleteResponse = await response.json();
            store.dispatch(paraphrasingActions.deleteParaphrasingFile(params));
            return deleteResponse;
        } catch(e) {
            console.log('error deleting project', e);
        }
    },
    getUser: async() => {
        try {
            const userSession = await LoginService.getUserSession();
            const response = await fetch('https://myok4hinbg.execute-api.us-east-2.amazonaws.com/getUser',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userSession.getIdToken().getJwtToken(),
                },
            });
            const user = (await response.json()).Item;
            return user;
        } catch(e) {
            console.log('error fetching user', e);
            return null;
        
        }
    },
    refreshUser: async() => {
        try {
            const user = await ParaphrasingService.getUser();
            console.log('refreshedUser', user);
            store.dispatch(userActions.setUser(user));
        } catch(e) {
            console.log('error refreshing user', e);
        }
    }
}

export default ParaphrasingService;