import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const RecentlyViewedSlice = createSlice({
    name: 'recentlyViewed',
    initialState: {},
    reducers: {
        setRecentlyViewed: (state, action) => {
            return action.payload;
        },
        addRecentlyViewed: (state: any, action: any) => {
            state[action.payload.contentID] = action.payload;
        },
        removeRecentlyViewed: (state: any, action: PayloadAction<{
            projectID: string,
            contentID: string,
        }>) => {
            const recentlyViewedProject = state[action.payload.projectID].slice();
            if(recentlyViewedProject) {
                const indexOfContent = recentlyViewedProject.indexOf(action.payload.contentID);
                recentlyViewedProject.splice(indexOfContent, 1);
                console.log('recentlyViewedProject', recentlyViewedProject);
            }
            return {
                ...state,
                [action.payload.projectID]: recentlyViewedProject,
            }
        },
        clearRecentlyViewed: (state) => {
            return {};
        },
    }
});

export const recentlyViewedActions = { 
    ...RecentlyViewedSlice.actions,
  }
export default RecentlyViewedSlice.reducer;