import { CSSProperties } from "react";
import useColors from "../hooks/useColors";

const GradientBorderButton = ({onClick, children}: any) => {    
    const colors = useColors();

    const styles = {
        container: {
            background: `radial-gradient(circle at 100% 100%, ${colors.secondaryBG} 0, ${colors.secondaryBG} 5px, transparent 5px) 0% 0%/6px 6px no-repeat,
            radial-gradient(circle at 0 100%, ${colors.secondaryBG} 0, ${colors.secondaryBG} 5px, transparent 5px) 100% 0%/6px 6px no-repeat,
            radial-gradient(circle at 100% 0, ${colors.secondaryBG} 0, ${colors.secondaryBG} 5px, transparent 5px) 0% 100%/6px 6px no-repeat,
            radial-gradient(circle at 0 0, ${colors.secondaryBG} 0, ${colors.secondaryBG} 5px, transparent 5px) 100% 100%/6px 6px no-repeat,
            linear-gradient(${colors.secondaryBG}, ${colors.secondaryBG}) 50% 50%/calc(100% - 2px) calc(100% - 12px) no-repeat,
            linear-gradient(${colors.secondaryBG}, ${colors.secondaryBG}) 50% 50%/calc(100% - 12px) calc(100% - 2px) no-repeat,
            linear-gradient(130deg, ${colors.gradientBorderStart} 0%, ${colors.gradientBorderEnd} 100%)`,
            borderRadius: 6,
            padding: '10px 20px 10px 20px',
            boxSizing: 'border-box',
            width: 'max-content',
            height: 'max-content',
            cursor: 'pointer',
        } as CSSProperties
    }

    return (
        <div style={styles.container} onClick={onClick}>
            <button>
                <p className="text-sm font-semibold leading-6">{children}</p>
            </button>
        </div>
    );
}

export default GradientBorderButton;