import { useEffect } from "react";
import LoginService from "../../auth/LoginService";
import { useNavigate } from "react-router-dom";

const Logout: React.FC = () => {

    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(async() => {
            // clear store
            await LoginService.logout();
            navigate('/', { replace: true });
        }, 1000);
    }, []);

    return (
        <div>
            <h1>Logging out...</h1>
        </div>
    );
}

export default Logout;