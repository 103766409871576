import { DocumentIcon, FolderIcon, XCircleIcon, DocumentDuplicateIcon as DocumentDuplicateIconOutline } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useParams } from "react-router-dom";
import useColors from "../hooks/useColors";
import { ArrowLeftOnRectangleIcon, DocumentDuplicateIcon, CurrencyDollarIcon, FolderOpenIcon } from "@heroicons/react/24/solid";
import { useEffect } from "react";
import useSidebar from "../hooks/useSidebar";

type MyContentSidebarProps = {
    toggleSidebar: () => void;
}

const MyContentSidebar: React.FC<MyContentSidebarProps> = ({toggleSidebar}) => {

    const projects = useSelector((state: any) => state.projects);
    const { projectID } = useParams();
    const location = useLocation();
    const colors = useColors();
    
    const topOptions = [{
            displayName: 'Paraphrasing',
            icon: location.pathname.includes('paraphrasing') ? DocumentDuplicateIcon : DocumentDuplicateIconOutline,
            path: '/app/my-content/paraphrasing',
            className: 'text-white font-medium text-[18px]',
            iconStyle: {
                color: location.pathname.includes('paraphrasing') ? colors.gradientBorderStart : 'white',
                opacity: location.pathname.includes('paraphrasing') ? 1 : 0.5
            },
            textStyle: {
                opacity: location.pathname.includes('paraphrasing') ? 1 : 0.5
            }
        },
        {
        displayName: 'Projects',
        icon: location.pathname.includes('projects') ? FolderOpenIcon : FolderIcon,
        path: '/app/my-content/projects',
        className: 'text-white font-medium text-[18px]',
        iconStyle: {
            color: location.pathname.includes('projects') ? colors.gradientBorderStart : 'white',
            opacity: location.pathname.includes('projects') ? 1 : 0.5
        },
        textStyle: {
            opacity: location.pathname.includes('projects') ? 1 : 0.5
        },
        children: Object.entries(projects).map(([pID, project]: any) => {
            return {
                displayName: project.title,
                icon: projectID === pID  ? FolderOpenIcon : FolderIcon,
                path: `/app/my-content/projects/${pID}`,
                className: 'text-white font-medium text-[14px]',
                iconStyle: {
                    color: projectID === pID ? colors.gradientBorderStart : 'white',
                    opacity: projectID === pID ? 1 : 0.5
                },
                textStyle: {
                    opacity: projectID === pID ? 1 : 0.5
                }
            }
        })
    }];
    const bottomOptions = [{
        displayName: 'Buy Word Credits',
        icon: CurrencyDollarIcon,
        path: '/app/buy-credits',
        className: 'text-white font-medium text-[18px]',
        textStyle: {

        },
        iconStyle: {
            display: 'none'
        },
    }, {
        displayName: 'Contact Us',
        icon: DocumentIcon,
        path: '/app/my-content/contact',
        className: 'text-white font-medium text-[18px]',
        textStyle: {
            color: location.pathname.includes('contact') ? colors.gradientBorderStart : 'white',
        },
        iconStyle: {
            display: 'none'
        }
    }, {
        displayName: 'Log out',
        icon: ArrowLeftOnRectangleIcon,
        path: '/logout',
        className: 'text-white font-medium text-[18px]',
        textStyle: {

        },
        iconStyle: {
            display: 'none'
        }
    }];

    return (
        <div id="sidebar" className="flex flex-col justify-between h-full border-r-[2px] border-[#3E3E69]">
            <div id="sidebar-content">
                <div id="sidebar-title" className="flex justify-center items-center relative">
                    <p className="flex-grow flex justify-center items-center text-white text-[22px] p-1 m-5">
                        <span className="font-medium mr-1">Lazy</span>
                        <span className="font-bold">Write</span>

                    </p>
                    <div className="absolute right-0 p-[12px] cursor-pointer lg:hidden" onClick={() => toggleSidebar()}>
                        <XCircleIcon className="text-white h-5 w-5" />
                    </div>
                </div>
                <div id="sidebar-menu" className="flex flex-col items-start">
                    <nav className="w-full pl-[8px]">
                        {topOptions.map((option, index) => {
                            return (
                                <>
                                <NavLink to={option.path} className="flex rounded items-center p-2">
                                    <option.icon 
                                        className="text-white h-5 w-5 mr-[4px] shrink-0" 
                                        style={option.iconStyle}
                                    />
                                    <div 
                                        key={index} 
                                        className={option.className}
                                        style={option.textStyle}
                                    >
                                        {option.displayName}
                                    </div>
                                </NavLink>
                                {option.children && option.children.map((childOption, index) => {
                                    return (
                                        <NavLink to={childOption.path} className="flex rounded items-center p-2 ml-4">
                                            <childOption.icon 
                                                className="text-white h-5 w-5 mr-1 shrink-0" 
                                                style={childOption.iconStyle}
                                            />
                                            <div 
                                                key={index} 
                                                className={childOption.className}
                                                style={childOption.textStyle}
                                            >
                                                {childOption.displayName}
                                            </div>
                                        </NavLink>
                                    )
                                })}
                                </>
                            )
                        })}
                        {/* horizontal line */}
                        <div 
                            className="border-t my-2"
                            style={{
                                borderColor: '#3E3E69'
                            }}
                        />
                        {bottomOptions.map((option, index) => {
                            return (
                                <NavLink to={option.path} className="flex rounded items-center p-2">
                                    <option.icon 
                                        className="text-white h-5 w-5 mr-1 shrink-0" 
                                        style={option.iconStyle}
                                    />
                                    <div 
                                        key={index} 
                                        className={option.className}
                                        style={option.textStyle}
                                    >
                                        {option.displayName}
                                    </div>
                                </NavLink>
                            )
                        })}
                    </nav>
                </div>
            </div>
            <div id="sidebar-footer" className="text-white font-medium text-lg p-2">
                <div className="flex flex-col items-center">
                    <div className="text-sm">
                        © 2024 Lazy Write
                    </div>
                    <div className="text-sm">
                        All Rights Reserved
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyContentSidebar;