import { CSSProperties, forwardRef } from "react";
import useColors from "../hooks/useColors";

type InfoCardProps = {
    title: string;
    description: string;
    icon: React.ReactNode;
    borderColor?: string;
    className?: string;
};

const InfoCard = forwardRef(({ title, description, icon, borderColor, className }: InfoCardProps, ref: any) => {

    const colors = useColors();

    if(borderColor === undefined) borderColor = colors.infoCardBorder;

    const styles = {
        cardBG: {
            justifyContent: 'space-between',
            background: `radial-gradient(circle at 100% 100%, ${colors.primaryBG} 0, ${colors.primaryBG} 30px, transparent 30px) 0% 0%/32px 32px no-repeat,
                radial-gradient(circle at 0 100%, ${colors.primaryBG} 0, ${colors.primaryBG} 30px, transparent 30px) 100% 0%/32px 32px no-repeat,
                radial-gradient(circle at 100% 0, ${colors.primaryBG} 0, ${colors.primaryBG} 30px, transparent 30px) 0% 100%/32px 32px no-repeat,
                radial-gradient(circle at 0 0, ${colors.primaryBG} 0, ${colors.primaryBG} 30px, transparent 30px) 100% 100%/32px 32px no-repeat,
                linear-gradient(${colors.primaryBG}, ${colors.primaryBG}) 50% 50%/calc(100% - 4px) calc(100% - 64px) no-repeat,
                linear-gradient(${colors.primaryBG}, ${colors.primaryBG}) 50% 50%/calc(100% - 64px) calc(100% - 4px) no-repeat,
                linear-gradient(0deg, ${colors.secondaryBorder} 0%, ${borderColor} 100%)`,
            borderRadius: 32,
            padding: 16,
            boxSizing: 'border-box',
            minHeight: 350,
        } as CSSProperties,
        description: {
            color: colors.primaryText,
        } as CSSProperties,
    }

    return (
        <div ref={ref} className={className} style={styles.cardBG}>
            <div>
                <h3 className="text-xl font-semibold">{title}</h3>
                <p 
                    className="mt-2 text-lg" 
                    style={styles.description}
                >
                    {description}
                </p>
            </div>
            <div className="flex justify-between items-center">
                <div className="flex-shrink-0">
                    {icon}
                </div>
                {/* Read More */}
            </div>
        </div>
    );

});

export default InfoCard;