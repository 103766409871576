export type SearchBarProps = {
    text: String;
}

export type Project = {
    title: string;
    description?: string;
    content?: Content | {};
}

export type Content = {
    id: string;
    title: string;
    description?: string;
    body: string;
    image?: string;
    url?: string;
    tone: Tone;
    type: ContentType;
    wordCount: number;
    finalWordCount: number;
    suggestions?: (Suggestion|CompletedSuggestion)[];
}

export type ParaphrasingFile = {
    id: string,
    title: string,
    originalWordCount: number,
    targetWordCount: number,
    finalWordCount: number,
    originalText: string,
    finalText: string
}

export type Tone = 'Absurd' | 'Amused' | 'Appreciative' | 'Assertive' | 'Concerned' | 'Conversational' | 'Cooperative' | 'Curious' |
    'Encouraging' | 'Engaging' | 'Fearful' | 'Formal' | 'Friendly' | 'Horror' | 'Humorous' | 'Informal' | 'Joyful' |
    'Optimistic' | 'Persuasive' | 'Pessimism' | 'Respectfulness' | 'Sad' | 'Satirical' | 'Serious';

export type ContentType = 'Advertisement' | 'Article' | 'Blog Post' | 'Book Chapter' | 'Brochure' | 'Catalog' | 
    'Course Material' | 'Email' | 'Essay' | 'Flyer' | 'Game Guide' | 'Letter' |
    'Memo' | 'Menu' | 'News Article' | 'Newsletter' | 'Poem' | 'Poster' | 'Presentation' | 'Press Release' | 
    'Product Description' | 'Proposal' | 'Recipe' | 'Report' | 'Research Paper' | 'Review' | 'Short Story' | 
    'Speech' | 'Technical Document' | 'Thesis' | 'User Manual' | 'Video Script' | 'Website Content'

export type Suggestion = {
    title: string,
    description: string,
    tone: string,
    type: string,
    wordCount: number;
    generatedStatus: GeneratedStatus;
    generatedID?: string;
}

export type CompletedSuggestion = {
    generatedStatus: GeneratedStatus.SUCCESS;
    generatedID: string;
}

export enum GeneratedStatus {
    SUCCESS,
    PENDING,
    ERROR,
    NONE
}


export enum PageSetup {
    LOGIN,
    REGISTER,
    FORGOT_PASSWORD
}