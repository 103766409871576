import React, { useEffect, useState } from 'react';
import ReactSlider from 'react-slider'
import './PriceSlider.css'

import stripeLogo from '../theme/img/stripe.svg';

type PriceSliderParams = {
    text: string,
}

const PriceSlider: React.FC<PriceSliderParams> = (params) => {

    const pricePer10000Words = 3.49;

    const [value, setValue] = useState(10000);

    return (
        <div className='flex flex-col justify-center items-center pb-[12px]'>
            <p className="pb-[20px]">{params.text}</p>
            <p className='text-[#fff] pb-[20px] text-[18px] font-semibold text-center'>
                Words to cost calculator <span className="underline underline-offset-4">{Number(value).toLocaleString()}</span> words
            </p>
            <ReactSlider
                defaultValue={value}
                onChange={(value: number) => setValue(value)}
                className='slider'
                thumbClassName='slider-thumb'
                trackClassName='slider-track'
                step={10000}
                min={10000}
                max={1000000}
            />
            <div className='flex justify-end w-full md:w-[75%] pt-[12px]'>
                <img src={stripeLogo} className='z-50' />
            </div>
            <div className='flex items-center'>
                <p className="text-[#fff] text-[18px] font-semibold text-center pt-[20px] w-[150px]">
                    £{((value / 10000) * pricePer10000Words).toFixed(2)}
                </p>
            </div>
        </div>
    );

}

export default PriceSlider;
