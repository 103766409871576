export type CognitoLoginResponse = {
    code: CognitoLoginResponseCode;
    data?: any;
}

export enum CognitoLoginResponseCode {
    UNKNOWN_ERROR,
    USER_NOT_CONFIRMED,
    USER_NOT_FOUND,
    USER_NOT_AUTHORIZED,
    SUCCESS,
    MFA_REQUIRED,
    NEW_PASSWORD_REQUIRED,
}

export type CognitoRegisterResponse = {
    code: CognitoRegisterResponseCode;
    data?: any;
}

export enum CognitoRegisterResponseCode {
    UNKNOWN_ERROR,
    USER_ALREADY_EXISTS,
    INVALID_PASSWORD,
    SUCCESS,
    INVALID_CONFIRMATION_CODE,
    PHONE_NUMBER_EXISTS,
}

export enum CognitoRequestVerificationCodeResponseCode {
    UNKNOWN_ERROR,
    USER_NOT_FOUND,
    FAILED_TO_SEND_CODE,
    TOO_MANY_REQUESTS,
    SUCCESS,
}

export type CognitoRequestVerificationCodeResponse = {
    code: CognitoRequestVerificationCodeResponseCode;
    data?: any;
}

export enum CognitoConfirmVerificationCodeResponseCode {
    UNKNOWN_ERROR,
    INVALID_CONFIRMATION_CODE,
    EXPIRED_CONFIRMATION_CODE,
    USER_NOT_FOUND,
    TOO_MANY_REQUESTS,
    SUCCESS,
}

export type CognitoConfirmVerificationCodeResponse = {
    code: CognitoConfirmVerificationCodeResponseCode;
    data?: any;
}

