export type GetCheckoutSessionResponse = {
    code: GetCheckoutSessionResponseCode;
    data?: any;
}

export enum GetCheckoutSessionResponseCode {
    UNKNOWN_ERROR,
    INVALID_SESSION_ERROR,
    IDEMPOTENCY_ERROR,
    CARD_ERROR,
    API_ERROR,
    SUCCESS
}