import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import useColors from "../hooks/useColors";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import ConfirmModal from "./ConfirmModal";
import { useSelector } from "react-redux";
import ContentService from "../content-service/ContentService";
import { useParams } from "react-router-dom";
import ModifyModal from "./ModifyModal";
import ParaphrasingService from "../content-service/ParaphrasingService";

type ListOptionDropdownProps = {
    type: 'project' | 'content' | 'paraphrase',
    projectID?: string,
    contentID?: string
    fileID?: string
}

const ListOptionDropdown: React.FC<ListOptionDropdownProps> = (props) => {

    const colors = useColors();
    const projects = useSelector((state: any) => state.projects);
    const paraphrasingFiles = useSelector((state: any) => state.paraphrasingFiles);
    
    /* Get the projectID so it can be used when the type is content 
     * currently its not possible to pass the projectID from the parent component
     * when the list is using content objects
     */
    const { projectID }: any = useParams();

    const buttonRef: any = useRef(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [modifyModalOpen, setModifyModalOpen] = useState<boolean>(false);

    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [wordCount, setWordCount] = useState<number | null>(null);

    useEffect(() => {
        if(props.type === 'project' && props.projectID) {
            setTitle(projects[props.projectID].title);
            setDescription(projects[props.projectID].description);
        } else if (props.type === 'content' && props.contentID) {
            setTitle(projects[projectID].content[props.contentID].title);
            setDescription(projects[projectID].content[props.contentID].description);
            setWordCount(projects[projectID].content[props.contentID].wordCount);
        } else if (props.type === 'paraphrase' && props.fileID) {
            setTitle(paraphrasingFiles[props.fileID].title);
        }

    }, [projects, props.projectID, projectID, props.contentID, props.type, props.fileID, paraphrasingFiles]);

    async function deleteItem(e: any, confirmed: boolean = false) {
        if(e) {e.stopPropagation()};
        if(!confirmed) {
            setDeleteModalOpen(true);
            return;
        }
        if(props.type === 'project' && props.projectID) {
            await ContentService.deleteProject({ projectID: props.projectID });
        } else if(props.type === 'content' && props.contentID) {
            await ContentService.deleteContent({ projectID: projectID, contentID: props.contentID});
        } else if(props.type === 'paraphrase' && props.fileID) {
            await ParaphrasingService.deleteParaphrasingFile({ fileID: props.fileID });
        }
    }

    async function modifyItem(e: any, confirmed: boolean = false, title?: string, description?: string, wordCount?: number | null) {
        if(e) {e.stopPropagation()};
        if(!confirmed) {
            setModifyModalOpen(true);
            return;
        }

        if(props.type === 'project' && props.projectID) {

            await ContentService.updateProject({
                projectID: props.projectID,
                properties: {
                    title: title,
                    description: description,
                }
            });

        } else if(props.type === 'content' && props.contentID) {
            await ContentService.updateContent({ 
                projectID: projectID, 
                contentID: props.contentID, 
                properties: {
                    title: title,
                    description: description,
                    wordCount: wordCount as number
                }
            });
        } else if(props.type === 'paraphrase' && props.fileID) {
            await ParaphrasingService.updateParaphrasingFile({
                fileID: props.fileID,
                properties: {
                    title: title,
                }
            });
        }

        console.log('modify item');
    }

    return (
        <>
        <ConfirmModal
            isOpen={deleteModalOpen}
            toggleModal={() => { setDeleteModalOpen(!deleteModalOpen) }}
            onSubmit={() => { deleteItem(null, true)}}
            title={(<span className="font-normal">Delete <span className="font-medium">{title}</span></span>)} 
            message={(
                <>
                <p className="p-3">
                    This will permanently delete <span className="font-bold underline underline-offset-[2px]">{title}</span> and all of the content associated with it.
                </p>
                <p className="p-3">
                    Are you sure you want to delete? All of your data
                    will be permanently removed. This action cannot be undone."
                </p>
                </>
            )}
            submitText="Delete"
            submitColor="red"
        />
        <ModifyModal
            isOpen={modifyModalOpen}
            toggleModal={() => { setModifyModalOpen(!modifyModalOpen) }}
            onSubmit={(title, description, wordCount) => { modifyItem(null, true, title, description, wordCount)}}
            title={title}
            description={description}
            wordCount={wordCount}
            type={props.type}
        />

            
        <Menu as="div">
            {(menuState) => (
                <>
                <Menu.Button 
                    ref={buttonRef}
                    className="flex items-center justify-center rounded-[4px] py-[2px] px-[6px] w-max lg:invisible group-hover:visible"
                    style={{
                        backgroundColor: menuState.open ? colors.gradientBorderStart : colors.primaryBG,
                    }}
                    onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = colors.gradientBorderStart;
                    }}
                    onMouseLeave={(e) => {
                        if(!menuState.open) {
                            e.currentTarget.style.backgroundColor = colors.primaryBG;
                        } 
                    }}
                    onClick={(e) => {
                        e.stopPropagation(); // Prevent the row from being clicked
                    }}
                >
                    <EllipsisHorizontalIcon className="h-6 w-6 text-white" />
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                <Menu.Items className="absolute z-10 right-0 mt-2 mr-2 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                    <div className="px-1 py-1">
                    <Menu.Item>
                        {({ active }) => (
                        <button
                            className={`${
                            active ? 'bg-violet-500 text-white' : 'text-gray-900'
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            onClick={modifyItem}
                        >
                            Modify
                        </button>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                        <button
                            className={`${
                            active ? 'bg-violet-500 text-white' : 'text-gray-900'
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            onClick={deleteItem}
                        >
                            Delete
                        </button>
                        )}
                    </Menu.Item>
                    </div>
                    {/* <div className="px-1 py-1">
                    <Menu.Item>
                        {({ active }) => (
                        <button
                            className={`${
                            active ? 'bg-violet-500 text-white' : 'text-gray-900'
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                            Archive
                        </button>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                        <button
                            className={`${
                            active ? 'bg-violet-500 text-white' : 'text-gray-900'
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                            Move
                        </button>
                        )}
                    </Menu.Item>
                    </div> */}
                </Menu.Items>
                </Transition>
                </>
            )}
      </Menu>
      </>
    )

}

export default ListOptionDropdown;