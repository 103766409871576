import React, { useEffect, useRef } from "react";
import "./App.css";
import HomeHeader from "./components/HomeHeader";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/home-pages/LandingPage";
import About from "./pages/home-pages/About";
import MyContentPage from "./pages/app-pages/MyContentPage";
import Projects from "./pages/app-pages/Projects";
import Login from "./pages/home-pages/Login"
import Project from "./pages/app-pages/Project";
import ContentPage from "./pages/app-pages/ContentPage";
import store from "./storage/store";
import { projectActions } from "./storage/ProjectsSlice";
import BuyCreditsPage from "./pages/app-pages/BuyCreditsPage";
import CheckoutStatusPage from "./pages/app-pages/CheckoutStatusPage";
import useColors from "./hooks/useColors";
import Prices from "./pages/home-pages/Prices";
import Features from "./pages/home-pages/Features";
import ContentService from "./content-service/ContentService";
import { recentlyViewedActions } from "./storage/RecentlyViewedSlice";
import { userActions } from "./storage/UserSlice";
import Logout from "./pages/app-pages/Logout";
import useAuthState from "./hooks/useAuthState";
import AppContactForm from "./pages/app-pages/AppContactForm";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import HomeFooter from "./components/HomeFooter";
import ParaphrasePage from "./pages/app-pages/ParaphrasePage";
import ParaphraseContentPage from "./pages/app-pages/ParaphraseContentPage";
import ParaphrasingService from "./content-service/ParaphrasingService";
import { paraphrasingActions } from "./storage/ParaphrasingSlice";
import LandingPageParaphrasing from "./pages/home-pages/LandingPageParaphrasing";

function App() {

  const colors = useColors();
  const isAuthed = useAuthState();
  const domainGroupId = 'f098cbf2-3d2b-407d-9d27-7c4c78ca347b';

  const legalContainerRef: any = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'CookieDeclaration';
    script.src = 'https://consent.cookiebot.com/f098cbf2-3d2b-407d-9d27-7c4c78ca347b/cd.js';
    script.type = 'text/javascript';
    script.async = true;

    legalContainerRef?.current?.appendChild(script);

    return () => {
      legalContainerRef?.current?.removeChild(script);
    };
  }, [legalContainerRef]);

  useEffect(() => {

    const firebaseConfig = {
      apiKey: "AIzaSyDzw1JwD5cZVLr57iSwREArB1Y6LJ9A5qk",
      authDomain: "strong-minutia-394712.firebaseapp.com",
      projectId: "strong-minutia-394712",
      storageBucket: "strong-minutia-394712.appspot.com",
      messagingSenderId: "513582625009",
      appId: "1:513582625009:web:d6060be4d0627c457df950",
      measurementId: "G-WH3RV97YLG"
    };
    try {
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
    } catch (e) {
      console.error(e);
    }
    // analytics.app.automaticDataCollectionEnabled = true;
  }, []);

  useEffect(() => {

    console.log('initial useEffect on app start')

    const getUserData = async() => {

      const user = await ContentService.getUser();
      const projects = await ContentService.fetchProjects();
      const recentlyViewed = await ContentService.fetchRecentlyViewed();
      const paraphrasingFiles = await ParaphrasingService.fetchParaphrasingFiles();

      if(user) {
          store.dispatch(userActions.setUser(user));
      }

      if(projects) {
          store.dispatch(projectActions.setProjects(projects));
      }

      if(recentlyViewed) {
          store.dispatch(recentlyViewedActions.setRecentlyViewed(recentlyViewed));
      }

      if(paraphrasingFiles) {
          store.dispatch(paraphrasingActions.setParaphrasingFiles(paraphrasingFiles));
      }

    }

    getUserData();

  }, [])

  useEffect(() => {
    document.body.style.background = colors.primaryBG;
    document.body.style.color = colors.primaryText;
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<><HomeHeader /><LandingPage /><HomeFooter /></>}/>
        <Route path="/paraphrasing" element={<><HomeHeader /><LandingPageParaphrasing /><HomeFooter /></>}/>
        <Route path="/about" element={<><HomeHeader /><About /><HomeFooter /></>} />
        <Route path="/login" element={<><HomeHeader /><Login /><HomeFooter /></>} />
        <Route path="/prices" element={<><HomeHeader /><Prices /><HomeFooter /></> }/>
        <Route path="/features" element={<><HomeHeader /><Features /><HomeFooter /></>} />
        {isAuthed && (
          <>
          <Route path="/logout" element={<Logout />} />
          <Route path="/app/buy-credits" element={<BuyCreditsPage />} />
          <Route path="/app/buy-credits/status/:sessionID" element={<CheckoutStatusPage />} />

          <Route path="/app/my-content" element={<MyContentPage />}>
            <Route path="/app/my-content/contact" element={<AppContactForm />} />
            <Route path="/app/my-content/projects" element={<Projects />} />
            <Route path="/app/my-content/paraphrasing/" element={<ParaphrasePage />} />
            <Route path="/app/my-content/paraphrasing/:fileID" element={<ParaphraseContentPage />} />
            <Route path="/app/my-content/projects/:projectID" element={<Project />} />
            <Route path="/app/my-content/projects/:projectID/:contentID" element={<ContentPage />} />
          </Route>
        </>
        )}
      </Routes>
      {/* <div ref={legalContainerRef} className="legal__container" /> */}
    </div>
  );
}

export default App;
