import { CSSProperties, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import List from "../../components/List";
import CreateModal from "../../components/CreateModal";
import { ReactComponent as CreateNew } from "../../theme/img/card-icon/create-new.svg";
import useColors from "../../hooks/useColors";
import ContentService from "../../content-service/ContentService";
import * as DOMPurify from 'dompurify';
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import './Project.css';

const Project: React.FC = () => {

    const colors = useColors();

    const { projectID }: any = useParams();
    const project = useSelector((state: any) => {
        return state.projects[projectID];
    });

    const recentlyViewed = useSelector((state: any) => state.recentlyViewed[projectID]);
    
    useEffect(() => {

    }, [project, recentlyViewed]);

    const navigate = useNavigate();

    const fileFieldNames = ['title', 'description', 'wordCount'];
    const [showCreateContentModal, setShowCreateContentModal] = useState<boolean>(false);

    const navigateToFile = useCallback((contentID: number) => {
        navigate(`/app/my-content/projects/${projectID}/${contentID}`);
    }, [project]);

    const createContent = useCallback(async (title: string, description?: string, wordCount?: number) => {
        ContentService.addContent({
            projectID: projectID,
            title: title,
            description: description || '',
            wordCount: wordCount || 500 
        });

        return true;

    }, [project]);

    const styles = {
        newContent: {
            background: `radial-gradient(circle at 100% 100%, ${colors.primaryBG} 0, ${colors.primaryBG} 7px, transparent 7px) 0% 0%/8px 8px no-repeat,
                radial-gradient(circle at 0 100%, ${colors.primaryBG} 0, ${colors.primaryBG} 7px, transparent 7px) 100% 0%/8px 8px no-repeat,
                radial-gradient(circle at 100% 0, ${colors.primaryBG} 0, ${colors.primaryBG} 7px, transparent 7px) 0% 100%/8px 8px no-repeat,
                radial-gradient(circle at 0 0, ${colors.primaryBG} 0, ${colors.primaryBG} 7px, transparent 7px) 100% 100%/8px 8px no-repeat,
                linear-gradient(${colors.primaryBG}, ${colors.primaryBG}) 50% 50%/calc(100% - 2px) calc(100% - 16px) no-repeat,
                linear-gradient(${colors.primaryBG}, ${colors.primaryBG}) 50% 50%/calc(100% - 16px) calc(100% - 2px) no-repeat,
                linear-gradient(90deg, ${colors.gradientBorderStart} 0%, ${colors.gradientBorderEnd} 100%)`,
            borderRadius: 8,
            padding: 5,
            boxSizing: 'border-box',
            height: 205,
            width: 160,
        } as CSSProperties,
    }

    return (
        <div id="project-container" className="px-4 sm:px-4 md:px-4 lg:px-6">
            {project != null ? (
                <>
                    <CreateModal
                        isOpen={showCreateContentModal} 
                        toggleModal={() => setShowCreateContentModal(!showCreateContentModal)} 
                        onSubmit={createContent}
                        type="content"
                    />
                    <div id="project-header" className="flex pb-3 items-center">
                        <div id="title" className="flex justify-start font-medium text-3xl mr-2">
                            {project.title}
                        </div>
                    </div>
                    <div 
                        id="create-project" 
                        className='mb-6 p-[20px] rounded-[8px] overflow-scroll'
                        style={{background: colors.secondaryBG}}
                    >
                        {/* {recentlyViewed && recentlyViewed.length > 0 && ( */}
                            <p className="font-medium mb-[4px] ml-[190px]">
                                Recently viewed
                            </p>
                        {/* )} */}
                        <div className="flex justify-start gap-[12px] items-center">
                            <div 
                                className="flex flex-col justify-center items-center cursor-pointer shrink-0" 
                                onClick={() => setShowCreateContentModal(true)}
                                style={styles.newContent}
                            >
                                <CreateNew />
                                <p className="font-bold text-center">
                                    Create new<br/>content
                                </p>
                            </div>
                            {/* vertical seperator */}
                            {recentlyViewed && recentlyViewed.length > 0 && (
                                <div 
                                    className="border-r border-solid h-[85px]"
                                    style={{
                                        borderColor: colors.recentlyViewedCardBG
                                    }}
                                />
                            )}
                            {recentlyViewed && recentlyViewed.map((contentID: any) => {
                                return (
                                    <div 
                                        key={project.content[contentID].contentID}
                                        className="
                                            flex 
                                            flex-col 
                                            cursor-pointer 
                                            border 
                                            rounded-[8px] 
                                            p-[8px] 
                                            h-[205px] 
                                            w-[160px] 
                                            shrink-0
                                            shadow-[inset_0_0px_4px_rgba(78,78,199,0.75)]
                                        "
                                        style={{
                                            background: colors.recentlyViewedCardBG,
                                            borderColor: colors.primaryBorder,
                                        }}
                                        onMouseEnter={(e) => {
                                            e.currentTarget.style.background = colors.recentlyViewedCardBGHover;
                                        }}
                                        onMouseLeave={(e) => {
                                            e.currentTarget.style.background = colors.recentlyViewedCardBG;
                                        }}
                                        onClick={() => navigateToFile(contentID)}
                                    >
                                        <div className="flex justify-between items-center border-b mb-[4px] pb-[4px]">
                                            <p className="font-bold line-clamp-1">
                                                
                                                {project.content[contentID].title}
                                            </p>
                                            <DocumentTextIcon className="h-5 w-5 shrink-0" />
                                        </div>
                                        <p 
                                            id="recently-viewed-content"
                                            className="overflow-hidden line-clamp-[10] text-[10px]" 
                                            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(project.content[contentID].body)}}
                                        >
                                        </p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div id="files-container" className="flex">
                        <List
                            type="content"
                            items={project.content}
                            fieldNames={fileFieldNames}
                            onRowPress={(contentID: any) => {
                                console.log('navigate to content', contentID);
                                navigateToFile(contentID);
                            }}
                        />
                    </div>
                </>
            ) : (
                <div>Project not found</div>
            )
        }
        </div>
    )

}

export default Project;