import ContactUsForm from "../../components/ContactUsForm";

const AppContactForm: React.FC = () => {

    return (
        <div className="flex justify-center">
            <div className="w-[80%] md:w-[50%]">
                <ContactUsForm />
            </div>
        </div>
    )

}

export default AppContactForm;