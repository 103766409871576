// Modules
import { CSSProperties, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';
import PriceSlider from '../../components/PriceSlider';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import useColors from '../../hooks/useColors';

// Assets
import aiBG from '../../theme/img/ai-bg.png';
import patternBG from '../../theme/img/Pattern 2.png';
import smGradient from '../../theme/img/01.png';
import GradientBorderButton from '../../components/GradientBorderButton';
import InfoCard from '../../components/InfoCard';
import aiHeader from '../../theme/img/ai-header.png';
import step1 from '../../theme/img/landing-steps/step-1.png';
import step2 from '../../theme/img/landing-steps/step-2.png';
import step3 from '../../theme/img/landing-steps/step-3.png';
import step4 from '../../theme/img/landing-steps/step-4.png';
import step5 from '../../theme/img/landing-steps/step-5.png';
import { ReactComponent as CardIcon1 } from '../../theme/img/card-icon/01.svg'
import { ReactComponent as CardIcon2 } from '../../theme/img/card-icon/02.svg'
import { ReactComponent as CardIcon3 } from '../../theme/img/card-icon/03.svg'
import { ReactComponent as CardIcon4 } from '../../theme/img/card-icon/04.svg'
import { ReactComponent as CardIcon5 } from '../../theme/img/card-icon/05.svg'
import { ReactComponent as CardIcon6 } from '../../theme/img/card-icon/06.svg'

// CSS
import 'swiper/css';
import './LandingPage.css';
import { useNavigate } from 'react-router-dom';
import ContactUsForm from '../../components/ContactUsForm';
import ReactPlayer from 'react-player/lazy';

const LandingPageParaphrasing = () => {

    const colors = useColors();
    const navigate = useNavigate();

    const cardRefs: any = useRef([]);
    const imgRefs: any = useRef([]);
    const infoCardContainerRef = useRef(null);
    
    cardRefs.current = [];
    imgRefs.current = [];

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        
        cardRefs.current.forEach((card: any, index: number) => {
            if(index === 0) return;
            gsap.fromTo(card, {
                opacity: 0,
                x: index % 2 === 0 ? -100 : 100,
                y: 100
            }, {
                opacity: 1,
                y: 0,
                x: 0,
                duration: 0.5,
                scrollTrigger: {
                    trigger: card,
                    start: 'top 80%', // when the top of the trigger hits the top of the viewport
                    toggleActions: 'play none none reverse'
                }
            }
            )
        });
    }, []);

    const addToCardRefs = (el: any) => {
        if (el && !cardRefs.current.includes(el)) {
            cardRefs.current.push(el);
        }
    };

    const addToImgRefs = (el: any) => {
        if (el && !imgRefs.current.includes(el)) {
            imgRefs.current.push(el);
        }
    }

    return (
        <>
        <img
            src={smGradient}
            alt="sm-gradient"
            className="absolute z-[-10] w-full h-full top-[-100px] left-0 right-0 m-auto md:hidden opacity-25"
        />
        <div>
            <div className='relative md:h-[500px] lg:h-[801px]'>
                <div className="flex flex-col justify-center items-center px-[24px] md:block md:absolute z-[20] top-[20px] left-[20px] md:top-[75px] md:left-[75px] lg:top-[100px] lg:left-[100px]">
                    <p className="text-center text-[28px] md:text-[50px] md:text-left lg:w-[600px]">
                        Fast-track your work <span className="bg-[#000000] bg-opacity-[0.25] rounded">with the</span> power of 
                        {' '}
                        <span style={styles.gradientText}>
                            AI Generated
                        </span>
                        {' '}
                        <span style={styles.gradientText2}>
                            Content & Paraphrasing
                        </span>
                    </p>
                    <p className='text-center font-medium pt-[12px] pb-[22px] md:text-left'>
                        High quality & authentic content for a fraction of the cost.</p>
                    <GradientBorderButton onClick={() => navigate('/login?type=register')}>
                        Claim 2,000 Free Words
                    </GradientBorderButton>
                </div>
                <img 
                    src={patternBG}
                    alt="pattern-bg"
                    className="hidden absolute z-[-5] left-0 right-0 m-auto md:block"
                />
                <img
                    src={aiBG}
                    alt="ai-header-mixed"
                    className="hidden absolute top-[-100px] z-[-10] left-0 right-0 m-auto md:block"
                    width="1150"
                    height="801"
                />
                <img 
                    src={aiHeader}
                    alt="ai-header"
                    className="block px-[20px] mix-blend-color-dodge md:hidden"
                />
            </div>
            <div className="z-30">
                <p className="my-[36px] text-center font-bold mb-[12px] text-[28px] lg:text-[40px]">
                    How It Works
                </p>
                <div className='lg:hidden p-[24px]'>
                    
                </div>
                <div className="justify-center hidden lg:flex ">
                    <ReactPlayer
                        url='videos/paraphrase-content.mp4'
                        width='75%'
                        height="auto"
                        controls={true}
                    />
                </div>
                <div className="justify-center flex lg:hidden">
                    <ReactPlayer
                        url='videos/paraphrase-mobile.mp4'
                        width='90%'
                        height="auto"
                        controls={true}
                    />
                </div>
            </div>
            <div className="flex relative justify-center my-[36px] font-bold text-[28px] lg:text-[40px] z-30">
                Why Choose Us?
            </div>
            <div ref={infoCardContainerRef} className="flex flex-wrap justify-center gap-[20px] relative z-3 mb-8 overflow-hidden">
                <InfoCard
                    ref={addToCardRefs}
                    title="Bleeding Edge Technology"
                    description="We utilize the power of the latest GPT-4o AI engine to ensure your content is paraphrased to the best quality possible."
                    icon={<CardIcon1 />}
                    borderColor='#289cdb'
                    className='flex shrink-0 flex-col basis-[90%] md:basis-1/3 lg:basis-1/4'
                />
                <InfoCard
                    ref={addToCardRefs}
                    title="Paraphrasing Made Easy"
                    description="Our paraphrasing tool efficiently restructures your content without losing the original context. Simply paste your content and let our AI do the rest."
                    icon={<CardIcon4 />}
                    borderColor='#01A535'
                    className='flex shrink-0 flex-col basis-[90%] md:basis-1/3 lg:basis-1/4'
                />
                <InfoCard
                    ref={addToCardRefs}
                    title="Simple Payment Model"
                    description="We charge a fixed rate per 10,000 words, simply top-up your word allowance and only spend for what you use. No hidden fees or unexpected re-occuring subscription bills down the line."
                    icon={<CardIcon3 />}
                    borderColor='#18BD82'
                    className='flex shrink-0 flex-col basis-[90%] md:basis-1/3 lg:basis-1/4'
                />
                <InfoCard
                    ref={addToCardRefs}
                    title="Content Writing"
                    description="Our system also generates content for you, based on your project requirements. Simply provide a title and a brief description, and our AI will generate content for you."
                    icon={<CardIcon2 />}
                    borderColor='#E73396'
                    className='flex shrink-0 flex-col basis-[90%] md:basis-1/3 lg:basis-1/4'
                />
                <InfoCard
                    ref={addToCardRefs}
                    title="Choose Your Purpose"
                    description="Whether it’s articles, exam papers, newsletters, advertisements, or video scripts, we’ve got you. Our paraphrasing tool can be used for a wide variety of content types."
                    icon={<CardIcon5 />}
                    borderColor='#5217CF'
                    className='flex shrink-0 flex-col basis-[90%] md:basis-1/3 lg:basis-1/4'
                />
                <InfoCard
                    ref={addToCardRefs}
                    title="Easy, Fast & Efficient"
                    description="Save hundreds of hours - provide the original text & a target word count, then your paraphrased text will be available within seconds."
                    icon={<CardIcon6 />}
                    borderColor='#E68337'
                    className='flex shrink-0 flex-col basis-[90%] md:basis-1/3 lg:basis-1/4'
                />
            </div>
            {/* <div className="my-[36px] z-30"> */}
                {/* <img
                    src={smGradient}
                    alt="sm-gradient"
                    className="absolute w-full h-full left-0 right-0 z-[-10] m-auto opacity-25"
                /> */}
            {/* </div> */}
            <div className="px-[24px] pb-[12px] md:px-[120px]">
                <div className="flex justify-between flex-wrap md:gap-[10%]">
                    <div className="basis-[100%] md:basis-[45%]">
                        <div className="flex justify-center my-[12px] font-bold text-[28px] lg:text-[40px] z-30">
                            Pricing
                        </div>
                        <PriceSlider text="Transparent payment model - simply top up your word allowance and spend as you please. When paraphrasing you only pay for the length of the original text, we don't charge for final paraphrased text." />
                        <div className="flex justify-center pb-[24px]">
                            <GradientBorderButton onClick={() => navigate('/login?type=register')}>
                                Claim 2,000 Free Words
                            </GradientBorderButton>
                        </div>
                    </div>
                    <div className="basis-[100%] md:basis-[45%] border-t md:border-t-0"
                        style={{
                            borderColor: colors.primaryBorder
                        }}
                    >
                        <ContactUsForm />
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

const styles = {
    aiHeaderImg: {
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
    } as CSSProperties,
    patternBG: {
        position: 'absolute',
        zIndex: 10,
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 988,
    } as CSSProperties,
    headlineContainer: {
        top: 200,
        left: 100,
        position: 'absolute',
        zIndex: 20,
    } as CSSProperties,
    headlineText: {
        fontSize: 50,
        fontWeight: 500,
    } as CSSProperties,
    gradientText: {
        background: 'linear-gradient(to right, #1B76FF 0%, #FF3AEB 100%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        fontWeight: 'bold'
    },
    gradientText2: {
        background: 'linear-gradient(to right, #1B76FF 0%, #5784FF 100%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        fontWeight: 'bold'
    },
}

export default LandingPageParaphrasing;