import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useSidebar = () => {

    const location = useLocation();
    const [isVisible, setIsVisible]: [boolean, any] = useState(false);

    useEffect(() => {

        updateVisibility();
        window.addEventListener('resize', updateVisibility);

        return () => {
            window.removeEventListener('resize', updateVisibility);
        }

    }, [location]);

    const updateVisibility = () => {

        const isMobileScreen = window.innerWidth <= 1024;
        const hiddenSidebarRoute = /projects\/.+\/.+/g;
        const hiddenSidebarRoute2 = /paraphrasing\/.+/g;
        const onHiddenLocation = hiddenSidebarRoute.test(location.pathname) || hiddenSidebarRoute2.test(location.pathname);

        const show = !isMobileScreen && !onHiddenLocation;
        setIsVisible(show);
    }

    return {
        sidebarVisible: isVisible,
        toggleSidebar: () => { console.log('toggledSidebar', !isVisible); setIsVisible(!isVisible)},
    }

}

export default useSidebar;