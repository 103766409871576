import { PhoneInput } from "react-international-phone";
import { PageSetup } from "../globalTypes";
import 'react-international-phone/style.css';
import LoginService from "../auth/LoginService";
import { CognitoConfirmVerificationCodeResponse, CognitoConfirmVerificationCodeResponseCode, CognitoLoginResponseCode, CognitoRegisterResponse, CognitoRegisterResponseCode, CognitoRequestVerificationCodeResponse, CognitoRequestVerificationCodeResponseCode } from "../cognitoTypes";
import { SubmitHandler, set, useForm } from "react-hook-form";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import './RegisterComponent.css'
import ContentService from "../content-service/ContentService";
import store from "../storage/store";
import { userActions } from "../storage/UserSlice";
import { projectActions } from "../storage/ProjectsSlice";
import { recentlyViewedActions } from "../storage/RecentlyViewedSlice";
import useColors from "../hooks/useColors";

type RegisterForm = {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    phoneNumber: string;
    confirmationCode: string;
}

const phoneUtil = PhoneNumberUtil.getInstance();
const isPhoneValid = (phone: string) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
};

const RegisterComponent: React.FC<{
    changePageSetup: any,
}> = ({
        changePageSetup
    }) => {

        const {
            register,
            handleSubmit,
            watch,
            formState: { errors },
        } = useForm<RegisterForm>();

        const navigate = useNavigate();

        const [phoneNumber, setPhoneNumber] = useState<string>('');
        const [phoneInputTouched, setPhoneNumberTouched] = useState<boolean>(false);
        const [isValid, setisPhoneValid] = useState<boolean>(false);

        const confirmCodeRef = useRef(null);
        const confirmRegisterRef = useRef(null);
        
        useEffect(() => {
            setisPhoneValid(isPhoneValid(phoneNumber));
        }, [phoneNumber]);

        const [awaitingConfirmation, setAwaitingConfirmation] = useState<boolean>(false);
        const [hidePassword, setHidePassword] = useState<boolean>(true);

        const colors = useColors();

        function showToastError(message: string) {
            toast.error(message, {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        const requestVerificationCode = async() => {
            const email = watch('email');
            try {
                const verificationCodeResponse = await LoginService.requestVerificationCode(email);
                console.log('verificationCodeResponse', verificationCodeResponse);
            } catch (error: any | CognitoRequestVerificationCodeResponse) {
                if(error.code === CognitoRequestVerificationCodeResponseCode.TOO_MANY_REQUESTS) {
                    showToastError('Too many requests, please try again later');
                    return;
                }

                if(error.code === CognitoRequestVerificationCodeResponseCode.FAILED_TO_SEND_CODE) {
                    showToastError('Failed to send code, please press resend');
                    return;
                }

                showToastError('Unknown when sending code, please try again later');
                console.log('verificationCodeError', error);
            }
        }

        const confirmVerificationCode = async() => {
            const email = watch('email');
            const password = watch('password');
            const code = watch('confirmationCode');
            try {

                if(confirmCodeRef && confirmCodeRef.current) {
                    const confirmRefButton = confirmCodeRef.current as HTMLButtonElement;
                    confirmRefButton.disabled = true;
                    confirmRefButton.style.opacity = '0.5';
                    confirmRefButton.innerHTML = 'Please wait...'
                }

                const verificationCodeResponse: CognitoConfirmVerificationCodeResponse 
                    = await LoginService.confirmRegistration(email, code);

                if(verificationCodeResponse.code === CognitoConfirmVerificationCodeResponseCode.SUCCESS) {
                    const loginResponse = await LoginService.loginWithPassword(email, password);
                    if(loginResponse.code === CognitoLoginResponseCode.SUCCESS) {
                        await fetchUserData();
                        changePageSetup(PageSetup.LOGIN);
                        navigate('/app/my-content/projects');
                        return;
                    } 

                }

                console.log('verificationCodeResponse', verificationCodeResponse);
            } catch (error: any | CognitoConfirmVerificationCodeResponse) {
                console.log('verificationCodeError', error);

                if(confirmCodeRef && confirmCodeRef.current) {
                    const confirmRefButton = confirmCodeRef.current as HTMLButtonElement;
                    confirmRefButton.disabled = false;
                    confirmRefButton.style.opacity = '1';
                    confirmRefButton.innerHTML = 'Confirm'
                }

                if(error.code === CognitoConfirmVerificationCodeResponseCode.INVALID_CONFIRMATION_CODE) {
                    showToastError('Invalid code');
                    return;
                }

                if(error.code === CognitoConfirmVerificationCodeResponseCode.EXPIRED_CONFIRMATION_CODE) {
                    showToastError('Code has expired');
                    return;
                }

                if(error.code === CognitoConfirmVerificationCodeResponseCode.TOO_MANY_REQUESTS) {
                    showToastError('Too many requests, please try again later');
                    return;
                }
            }
        }
    
        const onRegisterSubmit: SubmitHandler<RegisterForm> = async(data: any) => {
            try {

                const email = watch('email');
                const password = watch('password');

                if(confirmRegisterRef && confirmRegisterRef.current) {
                    const confirmRefButton = confirmRegisterRef.current as HTMLButtonElement;
                    confirmRefButton.disabled = true;
                    confirmRefButton.style.opacity = '0.5';
                    confirmRefButton.innerHTML = 'Please wait...'
                }

                if(!isValid) {
                    console.log('phone not valid')
                    setPhoneNumberTouched(true);
                    return;
                }

                const registerResponse: CognitoRegisterResponse = await LoginService.registerWithPassword(
                    data.email, 
                    data.password, 
                    phoneNumber
                );

                if(registerResponse.code === CognitoRegisterResponseCode.SUCCESS) {

                    const userConfirmed = registerResponse.data.userConfirmed;

                    if(!userConfirmed) {
                        setAwaitingConfirmation(true);

                        if(confirmRegisterRef && confirmRegisterRef.current) {
                            const confirmRefButton = confirmRegisterRef.current as HTMLButtonElement;
                            confirmRefButton.disabled = false;
                            confirmRefButton.style.opacity = '1';
                            confirmRefButton.innerHTML = 'Register'
                        }

                        return;
                    } else {
                        const loginResponse = await LoginService.loginWithPassword(email, password);
                        await fetchUserData();
                        changePageSetup(PageSetup.LOGIN);
                        navigate('/app/my-content/projects');
                        return;
                    }
                }
            } catch (error: any) {
                if(confirmRegisterRef && confirmRegisterRef.current) {
                    const confirmRefButton = confirmRegisterRef.current as HTMLButtonElement;
                    confirmRefButton.disabled = false;
                    confirmRefButton.style.opacity = '1';
                    confirmRefButton.innerHTML = 'Register'
                }
                if(error.code === CognitoRegisterResponseCode.USER_ALREADY_EXISTS) {
                    showToastError('This email address is already in use');
                    return;
                }

                if(error.code === CognitoRegisterResponseCode.INVALID_PASSWORD) {
                    showToastError('Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number');
                    return;
                }

                if(error.code === CognitoRegisterResponseCode.PHONE_NUMBER_EXISTS) {
                    showToastError('This phone number is already in use');
                    return;
                }

                showToastError('Unknown error, please try again later');
            }
        }

        const fetchUserData = async() => {

            const user = await ContentService.getUser();
            const projects = await ContentService.fetchProjects();
            const recentlyViewed = await ContentService.fetchRecentlyViewed();
    
            if(user) {
                store.dispatch(userActions.setUser(user));
            }
    
            if(projects) {
                store.dispatch(projectActions.setProjects(projects));
            }
    
            if(recentlyViewed) {
                store.dispatch(recentlyViewedActions.setRecentlyViewed(recentlyViewed));
            }
    
        }

        return (
            <div 
                className="flex flex-col sm:w-2/3 md:w-1/2 lg:w-1/3 rounded shadow p-4 min-h-80"
            >
                <ToastContainer />
                <p className="text-center font-medium pb-[12px]">
                    Register now for your <span style={{
                        color: colors.gradientBorderEnd
                    }}>free 2,000 word credits</span> deposited instantly!
                </p>
                {awaitingConfirmation ? (
                    <div className="flex flex-col">
                        <h2 className="mt-2">Confirmation Code</h2>
                        <p className="text-sm">Please enter the confirmation code sent to your email address</p>
                        <input
                            className="w-full rounded-[8px] p-2 mb-2 input-bg"
                            type="text"
                            {...register('confirmationCode', {
                                required: true
                            })}
                        />
                        <button 
                            ref={confirmCodeRef}
                            className="bg-blue-500 text-white rounded p-2 w-full"
                            onClick={() => confirmVerificationCode()}
                        >
                            Confirm
                        </button>
                        <p className="text-center mt-2">Didn't receive the code? <span onClick={requestVerificationCode} className="text-blue-500 cursor-pointer">Resend</span></p>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit(onRegisterSubmit)}>
                        <h2>Email</h2>  
                        <input 
                            className="w-full rounded-[8px] p-2 mb-2 input-bg"
                            type="text"
                            {...register('email', {
                                pattern: {
                                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                    message: 'Email isn\'t valid' // JS only: <p>error message</p> TS only support string
                                },
                                required: true
                            })}
                        />
                        {/* <p className="text-sm text-right text-gray-200/[0.5]">We will send a confirmation code to this email</p> */}
                        {errors.email && <p className="text-red-600 mt-2">{errors.email.message}</p>}
                        <div className="flex flex-row items-center justify-between mt-2">
                            <h2>Password</h2>
                            <p className="text-blue-500 cursor-pointer text-sm text-right" onClick={() => setHidePassword(!hidePassword)}>Show Password</p>
                        </div>
                        <input 
                            className="w-full rounded-[8px] p-2 mb-2 input-bg"
                            type={hidePassword ? 'password' : 'text'}
                            {...register('password', {
                                pattern: {
                                    //allow special characters
                                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                    message: 'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number'
                                },
                                required: true
                            })}
                        />
                        {watch('password') && (
                            <div>
                                <p>{watch('password').match(/(?=.*?[A-Z])/g) ? '✅' : '❌'} At least one upper case English letter</p>
                                <p>{watch('password').match(/(?=.*?[a-z])/g) ? '✅' : '❌'} At least one lower case English letter</p>
                                <p>{watch('password').match(/(?=.*?[0-9])/g) ? '✅' : '❌'} At least one digit</p>
                                <p>{watch('password').length >= 8 ? '✅' : '❌'} Minimum eight in length</p>
                            </div>
                        )}
                        {errors.password && <p className="text-red-600 mt-2">{errors.password.message}</p>}
                        <div className="flex flex-row justify-between mt-2">
                            <h2>Confirm Password</h2>
                            <p className="text-blue-500 cursor-pointer text-sm text-right" onClick={() => setHidePassword(!hidePassword)}>Show Password</p>
                        </div>
                        <input
                            className="w-full rounded-[8px] p-2 mb-2 input-bg"
                            type={hidePassword ? 'password' : 'text'}
                            {...register('confirmPassword', {
                                required: true,
                                validate: (val: string) => {
                                    if (watch('password') !== val) {
                                    return "Your passwords do not match";
                                    }
                                },
                            })}
                        />
                        {errors.confirmPassword && <p className="text-red-600 mt-2">{errors.confirmPassword.message}</p>}
                        <h2 className="mt-2">Phone Number</h2>
                        <PhoneInput
                            className="w-full rounded-[8px] p-2 mb-2 input-bg text-white"
                            defaultCountry="us"
                            inputStyle={{border: 'none', width: '100%', background: '#0E0C15', color: 'white'}}
                            value={phoneNumber}
                            onChange={(phone) => setPhoneNumber(phone)}
                            onBlur={() => setPhoneNumberTouched(true)}
                        />
                        <p className="text-sm text-right text-gray-200/[0.5]">We will send a confirmation code to this phone number</p>
                        {phoneInputTouched && !isValid && <div className="text-red-600 mt-2">Phone is not valid</div>}
                        <button 
                            ref={confirmRegisterRef}
                            className="bg-blue-500 text-white rounded p-2 w-full mt-[16px]"  
                            type="submit"
                        >
                            Register
                        </button>
                        <p className="text-center mt-[16px]">Already have an account? <span onClick={() => changePageSetup(PageSetup.LOGIN)} className="text-blue-500 cursor-pointer">Login</span></p>
                    </form>
                )}
            </div>
        ) as JSX.Element;
}

export default RegisterComponent;