import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ParaphrasingFile } from "../../globalTypes";
import ParaphrasingService from "../../content-service/ParaphrasingService";
import useColors from "../../hooks/useColors";
import GradientBorderButton from "../../components/GradientBorderButton";
import DOMPurify from "dompurify";
import LoginService from "../../auth/LoginService";
import store from "../../storage/store";
import { paraphrasingActions } from "../../storage/ParaphrasingSlice";
import { Dialog, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon, DocumentDuplicateIcon } from "@heroicons/react/24/solid";
import ConfirmModal from "../../components/ConfirmModal";


const ParaphraseContentPage: React.FC = () => {

    const { fileID }: any = useParams();

    const file = useSelector((state: any) => state.paraphrasingFiles[fileID]);

    const [title, setTitle] = useState<string>('');
    const [finalWordCount, setFinalWordCount] = useState<number>(0);
    const [originalText, setOriginalText] = useState<string>('');
    const [finalText, setFinalText] = useState<string>('');
    const [targetWordCount, setTargetWordCount] = useState<number>(0);

    const [requestingParaphrasing, setRequestingParaphrasing] = useState<boolean>(false);
    const [promptsSubmitted, setPromptsSubmitted] = useState<boolean>(file?.finalText != null && file?.finalText.length > 0);
    const [showSubPrompts, setShowSubPrompts] = useState<boolean>(file?.finalText == null || file?.finalText.length === 0);

    const [showConfirm, setShowConfirm] = useState<boolean>(false);


    const colors = useColors();
    const user = useSelector((state: any) => state.user);

    useEffect(() => {

        setTitle(file?.title ?? '');
        setOriginalText(file?.originalText ?? '');
        setFinalWordCount(file?.finalWordCount ?? 0);
        setTargetWordCount(file?.targetWordCount ?? 0);
        setFinalText(file?.finalText ?? '');


    }, [file]);

    useEffect(() => {
        const tempPromptsSubmitted = file?.finalText != null && file?.finalText.length > 0;
        console.log('file', file)
        console.log('tempPromptsSubmitted', tempPromptsSubmitted)
        if(tempPromptsSubmitted) {
            setPromptsSubmitted(tempPromptsSubmitted);
            setShowSubPrompts(false);
        }
    }, [finalText]);

    const handleOriginalTextChange = (e: any) => {
        setOriginalText(e.target.value);
    };

    const handleOriginalTextBlur = () => {
        changeContentProperties({
            originalText: originalText
        });
    };

    const handleTitleChange = (e: any) => {
        setTitle(e.target.value);
    }

    const handleTitleBlur = () => {
        changeContentProperties({
            title: title
        })
    }

    const changeContentProperties = async(properties: Partial<ParaphrasingFile>) => {
        ParaphrasingService.updateParaphrasingFile({
            fileID: fileID, 
            properties: properties
        });
    }

    const getWordCount = (text: string) => {
        const actualWords = text.split(/\s+/).filter(Boolean);
        return actualWords.length;
    }

    const sendMessage = async(confirmed: boolean) => {

        if(!confirmed) {
            setShowConfirm(true);
            return;
        }

        const originalWordCount = getWordCount(originalText);
        if(originalWordCount > user?.availableCredits) {
            alert('Not enough word credits to generate request, please lower the word count or top up your credits.');
            return;
        }

        setRequestingParaphrasing(true);
        setPromptsSubmitted(true);
        setShowSubPrompts(false);

        const userSession = await LoginService.getUserSession();
        const URL = 'https://a4nlirkzgll2xr4rma4btf7ocq0lzxgt.lambda-url.us-east-2.on.aws/';
        const response = await fetch(URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': userSession.getIdToken().getJwtToken(),
            },
            body: JSON.stringify({
                fileID: fileID,
                title: title,
                originalText: originalText,
                targetWordCount: targetWordCount,
            }),
        });

        // The response body is a stream
        const reader = response?.body?.getReader();

        // Creating a TextDecoder to decode the Uint8Array to string
        const decoder = new TextDecoder();

        // Function to read the stream
        async function read() {
            let result;
            let tempFinalText = '';
            do {
                result = await reader?.read();
                if(result == null) {
                    console.log('Result is null');
                    break;
                }
                if (result?.done) {
                    console.log('Stream completed');
                    break;
                }
                // Convert Uint8Array to string
                const chunk = decoder.decode(result?.value, { stream: true });
                setFinalText(prev => prev + chunk);
                tempFinalText += chunk;
                setFinalWordCount(getWordCount(tempFinalText))
            } while (!result?.done);

                store.dispatch(paraphrasingActions.updateParaphrasingFile({
                    fileID: fileID, 
                    properties: { finalText: tempFinalText, finalWordCount: getWordCount(tempFinalText)}
                }));
        }

        // Start reading the stream
        read();

        await ParaphrasingService.refreshUser();
        setRequestingParaphrasing(false);
    }

    const handleTargetWordCountChange = (val: any, updateServer: boolean) => {
        if(Number.isInteger(parseInt(val)) === false){
            val = 0
        };
        setTargetWordCount(parseInt(val));
        if(updateServer) {
            if(parseInt(val) < 49) {
                val = 50;
            }
            if(parseInt(val) > 10000) {
                val = 10000;
            }
            setTargetWordCount(parseInt(val));
            changeContentProperties({
                targetWordCount: parseInt(val)
            });
        }
    }

    return (
        <div 
            id="paraphrasing-container"
            className="px-4 py-2 sm:px-2 py-2 md:px-4 py-4 lg:px-40 py-4"
        >
            <ConfirmModal
                isOpen={showConfirm}
                toggleModal={() => setShowConfirm(!showConfirm)}
                onSubmit={() => sendMessage(true)}
                title={(<span className="font-medium">Confirm Paraphrasing Request</span>)}
                message={(
                    <p>
                        The word count from the original text ({getWordCount(originalText)} words) will be subtracted from your word credits, do you wish to continue?
                    </p>
                )}
                submitText="Confirm"
                submitColor={'#10B981'}
                modalIcon={DocumentDuplicateIcon}
            />
            {file && (
                <div className="paraphrase-bg">
                    <div 
                        id="content-prompts" 
                        className="relative px-[12px] pt-[12px] rounded-[8px] mb-8"
                        style={{
                            position: 'relative',
                            background: 'rgb(10, 10, 29, 0.75)',
                            border: `2px solid ${colors.primaryBorder}`,
                        }}
                    >
                        <div id="content-header" className="flex-col items-center mb-[12px]">
                            <p
                                id="content-title-label"
                                className="text-gray-500 text-sm mb-[6px]"
                            >
                                Title
                            </p>
                            <div className="relative mb-[12px]">
                                <input
                                    id="title"
                                    className="flex justify-start font-medium text-3xl mr-2 w-full"
                                    style={{
                                        background: "transparent",
                                    }}
                                    value={title}
                                    onChange={handleTitleChange}
                                    onBlur={handleTitleBlur}
                                />
                                <div className="absolute right-0 top-[4px] opacity-25">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <Transition
                            show={showSubPrompts}
                            enter="transition-all duration-1000"
                            enterFrom="opacity-0 h-0"
                            enterTo="opacity-100 min-h-[240px]"
                            leave="transition-all duration-1000"
                            leaveFrom="opacity-100 min-h-[240px]"
                            leaveTo="opacity-0 h-0"
                        >
                        {promptsSubmitted ? (
                            <>
                            <div id="content-brief-label" className="text-gray-500 text-sm mb-[12px]">
                                Original text
                            </div>
                            <p 
                                className="text-white text-md mb-[12px]"
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(originalText.replaceAll('\n', '<br>')) }}
                            />
                            <div className="flex justify-start gap-[24px]">
                                <div className="flex flex-col justify-end">
                                    <p 
                                        id="content-tone-label" 
                                        className={`text-sm mb-1`}
                                        style={{color: colors.secondaryText}}
                                    >
                                        Original word count
                                    </p>
                                    <p className="text-white text-md mb-1">
                                        {getWordCount(originalText)}
                                    </p>
                                </div>
                                <div className="flex flex-col justify-end">
                                    <p 
                                        id="content-tone-label" 
                                        className={`text-sm mb-1`}
                                        style={{color: colors.secondaryText}}
                                    >
                                        Target word count
                                    </p>
                                    <p className="text-white text-md mb-1">
                                        {targetWordCount}
                                    </p>
                                </div>
                                <div className="flex flex-col justify-end">
                                    <p 
                                        id="project-wordCount" 
                                        className="text-sm mb-1"
                                        style={{color: colors.secondaryText}}
                                    >
                                        Final word count
                                    </p>
                                    <p className="text-white text-md mb-1">
                                        {finalWordCount}
                                    </p>
                                </div>
                            </div>
                            </>
                        ) : (
                            <>
                            <div className="flex justify-between items-center mb-1">
                                <div id="content-brief-label" className="text-gray-500 text-sm">
                                    Original text
                                </div>
                                <div id="content-wordCount" className="text-gray-200 text-sm">
                                    Word count: {getWordCount(originalText)}
                                </div>
                            </div>
                            <textarea 
                                id="content-brief-textarea" 
                                className="p-1 w-full h-[120px]"
                                style={{
                                    background: 'transparent', 
                                    border: `1px solid ${colors.primaryBorder}`,
                                    borderRadius: 4,
                                }}
                                value={originalText}
                                onChange={handleOriginalTextChange}
                                onBlur={handleOriginalTextBlur}
                            />
                            <div id="content-generate" className="flex justify-between items-end">
                                <div>
                                    <p 
                                        id="project-wordCount" 
                                        className="text-sm mb-1"
                                        style={{color: colors.secondaryText}}
                                    >
                                        Target word count
                                    </p>
                                    <div className="font-medium">
                                        <input 
                                            type="text" 
                                            inputMode="numeric"
                                            onChange={(e) => handleTargetWordCountChange(e.target.value, false)}
                                            onBlur={(e) => handleTargetWordCountChange(e.target.value, true)}
                                            className="w-[100px] p-1 text-center rounded-[8px] input-bg"
                                            value={targetWordCount}
                                        />
                                    </div>
                                </div>
                                <GradientBorderButton
                                    id="content-generate-button"
                                    onClick={() => {
                                        sendMessage(false);
                                    }}
                                    disabled={finalText.length > 0}
                                >
                                    Paraphrase
                                </GradientBorderButton>
                            </div>
                            </>
                        )}
                        </Transition>
                        <div 
                            className="flex justify-center"
                        >
                            <div 
                                className="px-[16px] rounded-t-[8px] rounded cursor-pointer"
                                style={{
                                    background: colors.secondaryBG,
                                }}
                                onClick={() => setShowSubPrompts(!showSubPrompts)}
                            >
                                {showSubPrompts ? (
                                    <ChevronDownIcon 
                                        className="h-6 w-6 text-gray-400 cursor-pointer"
                                    />
                                ) : (
                                    <ChevronUpIcon 
                                        className="h-6 w-6 text-gray-400 cursor-pointer"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div id="content-output" className="flex flex-col shrink mb-6">
                        <div className="flex justify-between">
                            <div id="content-output-label" className="text-gray-500 text-sm mb-1">Final text</div>
                            <div id="content-wordCount" className="text-gray-200 text-sm">Final word count: {finalWordCount}</div>
                        </div>
                        <div 
                            id="content-output-textarea" 
                            className="overflow-scroll p-[12px] w-full h-full min-h-[200px]"
                            style={{
                                background: colors.primaryBG,
                                borderRadius: 8,
                                border: `2px solid ${colors.primaryBorder}`,
                            }}
                        >
                            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(finalText) }} />
                        </div>
                    </div>
                </div>
                
            )}
        </div>
    );

}

export default ParaphraseContentPage;