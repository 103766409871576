import useColors from "../hooks/useColors";

const HomeFooter: React.FC = () => {

    const colors = useColors();

    return (
        <footer>
            <div 
                className="flex justify-center items-center border-t py-[36px] px-[24px] md:px-[120px] text-[#fff]"
                style={{
                    borderColor: colors.primaryBorder
                }}
            >
                <p className="text-[14px]">
                    © 2024 Lazy Write. All rights reserved.
                </p>
            </div>
        </footer>
    )
}

export default HomeFooter;