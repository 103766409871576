const DEFAULT_THEME = 'dark';

const useColors = () => {

    const theme = DEFAULT_THEME;

    const colors = {
        primaryBG: theme === 'dark' ? '#0E0C15' : '#fff',
        secondaryBG: theme === 'dark' ? '#19192B' : '#f5f5f5',
        sidebarBG: theme === 'dark' ? '#313154' : '#f5f5f5',
        icon: theme === 'dark' ? '#fff' : '#000',
        primaryText: theme === 'dark' ? '#fff' : '#000',
        secondaryText: theme === 'dark' ? '#6b7280' : '#000',
        gradientBorderStart: theme === 'dark' ? '#0093ff' : '#0093ff',
        gradientBorderEnd: theme === 'dark' ? '#FF3AEB' : '#FF3AEB',
        primaryBorder: theme === 'dark' ? '#323846' : '#0093ff',
        secondaryBorder: theme === 'dark' ? '#ADA8C3' : '#0093ff',
        infoCardBorder: theme === 'dark' ? '#289cdb' : '#0093ff',
        recentlyViewedCardBG: theme === 'dark' ? '#3D3D6A' : '#3D3D6A',
        recentlyViewedCardBGHover: theme === 'dark' ? '#24243E' : '#24243E',
        creditTextColor: theme === 'dark' ? '#34FFA0' : '#34FFA0'
    }

    return colors;

}

export default useColors;