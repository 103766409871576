import LoginService from "../auth/LoginService";
import { Content, Project } from "../globalTypes";
import { projectActions } from "../storage/ProjectsSlice";
import { recentlyViewedActions } from "../storage/RecentlyViewedSlice";
import store from "../storage/store";
import { userActions } from "../storage/UserSlice";

type UpdateContentParams = {
    projectID: string,
    contentID: string,
    properties: Partial<Content>
  }
  
type AddContentParams = {
    projectID: string,
    title: string,
    description: string,
    wordCount: number
}

type DeleteContentParams = {
    projectID: string,
    contentID: string,
}

type UpdateProjectParams = {
    projectID: string,
    properties: Partial<Project>
}

type DeleteProjectParams = {
    projectID: string,
}

type AddProjectParams = {
    title: string,
    description: string,
}

function getUniqueTitle(newTitle: string, existingTitles: string[]) {
    if (!existingTitles.includes(newTitle)) {
        return newTitle; // Return the title if it's not in the array
    }

    let counter = 1;
    let modifiedTitle;

    // Loop until a unique title is found
    do {
        modifiedTitle = `${newTitle} (${counter})`;
        counter++;
    } while (existingTitles.includes(modifiedTitle));

    return modifiedTitle; // Return the modified title
}

const ContentService = {
    requestSuggestion: async(
        projectID: string,  
        originalContentID: string,
        suggestionIndex: number,
        projectTitle: string, 
        title: string, 
        description: string, 
        tone: string, 
        type: string,
        wordCount: number
    ) => {

        try {
            const userSession = await LoginService.getUserSession();
            const url = 'https://24fjynct4mxnhi4jutsdyo2wt40giexp.lambda-url.us-east-2.on.aws/';

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userSession.getIdToken().getJwtToken(),
                },
                body: JSON.stringify({
                    projectID: projectID,
                    originalContentID: originalContentID,
                    suggestionIndex: suggestionIndex,
                    projectTitle: projectTitle,
                    title: title,
                    description: description,
                    tone: tone,
                    type: type,
                    wordCount: wordCount
                }),
            });

            const newContent = await response.json();
            store.dispatch(projectActions.addContent(newContent));

            return newContent.contentID;
        } catch(e) {
            console.log('error fetching suggestion', e);
        }

    },
    requestSuggestions: async(
        projectID: string, 
        contentID: string, 
        projectTitle: string, 
        title: string, 
        description: string, 
        contentTone: string, 
        contentType: string
    ) => {
        try {
            const userSession = await LoginService.getUserSession();
            const suggestions = await fetch('https://myok4hinbg.execute-api.us-east-2.amazonaws.com/generateSuggestions',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userSession.getIdToken().getJwtToken(),
                },
                body: JSON.stringify({
                    projectID: projectID,
                    contentID: contentID,
                    projectTitle: projectTitle,
                    title: title,
                    description: description,
                    tone: contentTone,
                    type: contentType,
                })
            });

            return (await suggestions.json());
        } catch(e) {
            console.log('error fetching suggestions', e);
        }
    },
    fetchProjects: async() => {
        try {
            const userSession = await LoginService.getUserSession();
            if(userSession) {
            const user = await ContentService.getUser();
            return user.projects;
            } 
        } catch(e) {
            console.log('error fetching projects', e);
        }
    },
    fetchRecentlyViewed: async() => {
        try {
            const userSession = await LoginService.getUserSession();
            if(userSession) {
            const user = await ContentService.getUser();
            return user.recentlyViewed;
            } 
        } catch(e) {
            console.log('error fetching recently viewed', e);
        }
    },
    addProject: async(params: AddProjectParams) => {
        try {
            if(params.title) {
                const existingTitles = Object.entries(store.getState().projects).map(([projectID, project]: any) => {
                    return project.title;
                });
                params.title = getUniqueTitle(params.title, existingTitles);
            }

            const userSession = await LoginService.getUserSession();
            const url = 'https://myok4hinbg.execute-api.us-east-2.amazonaws.com/addProject';

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userSession.getIdToken().getJwtToken(),
                },
                body: JSON.stringify({
                    title: params.title,
                    description: params.description,
                }),
            });

            const newProject: {
                projectID: string,
                title: string,
                description: string,
                content: any,
                updatedAt: string,
                createdAt: string,
            } = await response.json();
            console.log('ContentService.addProject response:', newProject);
            store.dispatch(projectActions.addProject(newProject));
            return true;
        } catch(e) {
            console.log('error adding project', e);
        }
    },
    updateProject: async(params: UpdateProjectParams) => {
        try {
            if(params.properties.title) {
                const existingTitles = Object.entries(store.getState().projects).map(([projectID, project]: any) => {
                    return project.title;
                });
                params.properties.title = getUniqueTitle(params.properties.title, existingTitles);
            }
            store.dispatch(projectActions.updateProject(params));
            const userSession = await LoginService.getUserSession();
            const url = 'https://myok4hinbg.execute-api.us-east-2.amazonaws.com/updateProject';

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userSession.getIdToken().getJwtToken(),
                },
                body: JSON.stringify(params),
            });

            const updateResponse = await response.json();
            return updateResponse;
        } catch(e) {
            console.log('error updating project', e);
        }
    },
    deleteProject: async(params: DeleteProjectParams) => {
        try {
            const userSession = await LoginService.getUserSession();
            const url = 'https://myok4hinbg.execute-api.us-east-2.amazonaws.com/deleteProject';

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userSession.getIdToken().getJwtToken(),
                },
                body: JSON.stringify(params),
            });

            const deleteResponse = await response.json();
            store.dispatch(projectActions.deleteProject(params));
            return deleteResponse;
        } catch(e) {
            console.log('error deleting project', e);
        }
    },
    addContent: async(params: AddContentParams) => {

        try {

            if(params.title) {
                // @ts-ignore
                const existingTitles = Object.entries(store.getState().projects[params.projectID].content).map(([contentID, content]: any) => {
                    return content.title;
                });
                params.title = getUniqueTitle(params.title, existingTitles);
            }

            const userSession = await LoginService.getUserSession();
            const url = 'https://myok4hinbg.execute-api.us-east-2.amazonaws.com/addContent';

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userSession.getIdToken().getJwtToken(),
                },
                body: JSON.stringify({
                    projectID: params.projectID,
                    title: params.title,
                    description: params.description,
                    wordCount: params.wordCount,
                }),
            });
            const newContent: {
                projectID: string,
                contentID: string,
                title: string,
                description: string,
                body: string,
                createdAt: string,
                updatedAt: string,
                wordCount: number,
            } = await response.json();
            store.dispatch(projectActions.addContent(newContent));
            return true;
        } catch(e) {
            console.log('error adding content', e);
        }
    },
    updateContent: async(params: UpdateContentParams) => {
        try {

            if(params.properties.title) {
                // @ts-ignore
                const existingTitles = Object.entries(store.getState().projects[params.projectID].content).map(([contentID, content]: any) => {
                    return content.title;
                });
                params.properties.title = getUniqueTitle(params.properties.title, existingTitles);
            }
            
            store.dispatch(projectActions.updateContent({
                projectID: params.projectID,
                contentID: params.contentID,
                properties: params.properties,
            }));

            const userSession = await LoginService.getUserSession();
            const url = 'https://myok4hinbg.execute-api.us-east-2.amazonaws.com/updateContent';

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userSession.getIdToken().getJwtToken(),
                },
                body: JSON.stringify(params),
            });

            const contentResponse = await response.json();
            return contentResponse;
        } catch(e) {
            console.log('error updating content', e);
        }
    },
    deleteContent: async(params: DeleteContentParams) => {
        try {
            const userSession = await LoginService.getUserSession();
            const url = 'https://myok4hinbg.execute-api.us-east-2.amazonaws.com/deleteContent';

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userSession.getIdToken().getJwtToken(),
                },
                body: JSON.stringify(params),
            });

            const deleteResponse = await response.json();

            store.dispatch(recentlyViewedActions.removeRecentlyViewed({
                projectID: params.projectID,
                contentID: params.contentID,
            }));

            store.dispatch(projectActions.deleteContent({
                projectID: params.projectID,
                contentID: params.contentID,
            }));

            return deleteResponse;
        } catch(e) {
            console.log('error deleting content', e);
        }
    },
    async addRecentlyViewed(projectID: string, contentID: string) {
        try {
            const userSession = await LoginService.getUserSession();
            const url = 'https://myok4hinbg.execute-api.us-east-2.amazonaws.com/addRecentlyViewed';

            await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userSession.getIdToken().getJwtToken(),
                },
                body: JSON.stringify({
                    projectID: projectID,
                    contentID: contentID,
                }),
            });

            console.log('added recently viewed');
        } catch(e) {
            console.log('error adding recently viewed', e);
        }

    },
    getUser: async() => {
        try {
            const userSession = await LoginService.getUserSession();
            const response = await fetch('https://myok4hinbg.execute-api.us-east-2.amazonaws.com/getUser',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userSession.getIdToken().getJwtToken(),
                },
            });
            const user = (await response.json()).Item;
            return user;
        } catch(e) {
            console.log('error fetching user', e);
            return null;
        
        }
    },
    refreshUser: async() => {
        try {
            const user = await ContentService.getUser();
            console.log('refreshedUser', user);
            store.dispatch(userActions.setUser(user));
        } catch(e) {
            console.log('error refreshing user', e);
        }
    }
}

export default ContentService;
