import { ChevronRightIcon } from "@heroicons/react/24/outline";
import './List.css';
import useColors from "../hooks/useColors";
import ListOptionDropdown from "./ListOptionDropdown";

interface ListProps {
    type: 'project' | 'content' | 'paraphrase';
    fieldNames: string[];
    items: any;
    onRowPress: any;
}

const List: React.FC<ListProps> = ({ type, fieldNames, items, onRowPress }) => {

    const colors = useColors();

    const capitliseFirstLetter = (text: string) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    return (
        <div id="list-container" className="w-full">
            <div 
                className="flex border-b" 
                style={{
                    borderColor: colors.secondaryBG
                }}
            >
                {fieldNames.map((name, index) => {
                    const hideOnSmall = index > 0;
                    return (
                        <div 
                            key={index}
                            className={`p-2 basis-[100%] lg:basis-[30%] ${hideOnSmall ? `hidden lg:block` : 'visible'}`}
                            style={{
                                flexGrow: index === fieldNames.length - 1 ? 1 : 0
                            }}
                        >
                            <p className="font-bold text-xl text-left py-2">
                                {capitliseFirstLetter(name)}
                            </p>
                        </div>
                    )
                })}
            </div>
            <div className="flex flex-col">
                {Object.entries(items).map(([itemID, item]: any) => (
                    <div 
                        key={itemID}
                        className="flex group border-b cursor-pointer justify-between"
                        style={{
                            borderColor: colors.sidebarBG,
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = colors.sidebarBG;
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = 'unset';
                        }}
                        onBlur={(e) => {
                            e.currentTarget.style.backgroundColor = 'unset';
                        }}
                        onClick={() => onRowPress(itemID)}
                    >
                        {fieldNames.map((fieldName, index) => {
                           const hideOnSmall = index > 0;
                            return (
                                <div 
                                    key={index}
                                    className={`p-2 basis-[100%] lg:basis-[30%] ${hideOnSmall ? `hidden lg:block` : 'visible'}`}
                                    style={{
                                        flexGrow: index === fieldNames.length - 1 ? 1 : 0,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                    {item[fieldName]}
                                </div>
                            );
                        }
                        )}
                        <div className="py-2 pr-2 self-end">
                             {type === 'project' ? (
                                <ListOptionDropdown projectID={itemID} type={type} />
                            ) : null}

                            {type === 'content' ? (
                                <ListOptionDropdown contentID={itemID} type={type} />
                            ) : null}
                            {type === 'paraphrase' ? (
                                <ListOptionDropdown fileID={itemID} type={type} />
                            ) : null}
                        </div>
                        <div className="hidden py-2 pr-2 lg:block">
                            <ChevronRightIcon className="h-6 w-6 text-white invisible group-hover:visible" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default List;