import { useEffect, useState } from "react";
import LoginService from "../auth/LoginService";
import { useLocation } from "react-router-dom";

const useAuthState = () => {

    const [currentUser, setCurrentUser]: any = useState(null);
    const location = useLocation();

    useEffect(() => {
        if(!currentUser) {

            //validate user session
            const userPool = LoginService.getUserPool();
            const cognitoUser = userPool.getCurrentUser();
            setCurrentUser(cognitoUser);

        }

    }, [location]) // check for user session on every location change

    return currentUser;

}

export default useAuthState;