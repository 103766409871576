import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import LoginService from '../auth/LoginService';
const stripePromise = loadStripe('pk_live_51Lj57vAtqRt2EobP8NSkGWqhcpOBplAD1XVKmZU9NOYxjRzZuzXedsNa0EgrfOz0fsZfOCoKE0W6KZXc9Hu1wMth00q7RYhvw1');

const CheckoutForm = () => {

  const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {

      const startSession = async () => {

        const userSession = await LoginService.getUserSession();
        const session = await fetch('https://myok4hinbg.execute-api.us-east-2.amazonaws.com/createStripeSession', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': userSession.getIdToken().getJwtToken(),
          },
          body: JSON.stringify({quantity: 1})
        })

        const data = await session.json();
        console.log('data', data);
        setClientSecret(data.client_secret);
      }

      startSession();

    }, []);

  return (
    <div id="checkout" className="h-full w-full">
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{clientSecret}}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export default CheckoutForm;