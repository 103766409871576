import { Outlet, useLocation, useNavigate } from "react-router-dom";
import MyContentSidebar from "../../components/MyContentSidebar";
import SearchBar from "../../components/SearchBar/SearchBar";
import { useEffect, useRef, useState } from "react";
import ProfileDropdown from "../../components/ProfileDropdown";
import { ArrowLeftIcon, Bars3Icon, PlusCircleIcon } from "@heroicons/react/24/outline";
import useSidebar from "../../hooks/useSidebar";
import useColors from "../../hooks/useColors";
import { useSelector } from "react-redux";

const MyContentPage = () => {

    const colors = useColors();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchBarVisible, setSearchBarVisible] = useState<boolean>(false);
    const {sidebarVisible, toggleSidebar} = useSidebar();
    const location = useLocation();
    const user = useSelector((state: any) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        console.log('sidebarVisibility', sidebarVisible);
    }, [sidebarVisible]);

    useEffect(() => {

        const hiddenSearchBarRoute = /projects\/.+\/.+/g;
        const hiddenSearchBarRoute2 = /paraphrasing\/.+/g;
        const showSearchBar = !hiddenSearchBarRoute.test(location.pathname) && !hiddenSearchBarRoute2.test(location.pathname);

        setSearchBarVisible(showSearchBar);

    }, [location]);

    let siderbarRef: any = useRef(null);
    useEffect(() => {
        function handleClickOutside(event: any) {
            const clickedOutsideSidebar = siderbarRef.current && !siderbarRef.current.contains(event.target)
            console.log('clickedOutsideSidebar', clickedOutsideSidebar);
            if (clickedOutsideSidebar) {
                // siderbarRef.current.style.display = 'none';
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [siderbarRef]);

    return (
        <div className="flex justify-between h-screen overscroll-contain">
            <div 
                className={`
                    ${sidebarVisible ? 'fixed' : 'hidden'}
                    lg:hidden
                    absolute 
                    top-0 
                    left-0 
                    w-screen 
                    h-full 
                    z-[999]
                    bg-black 
                    opacity-75`
                }
                onClick={() => toggleSidebar()} 
            />
            <aside 
                ref={siderbarRef}
                id="default-sidebar" 
                className={`
                    ${sidebarVisible ? 'fixed lg:relative' : 'hidden'}
                    top-0 
                    left-0
                    w-[70%]
                    z-[1000]
                    md:w-[40%]
                    lg:w-[20%]
                    h-full
                    shrink-0 
                    transition-transform ${sidebarVisible ? 'translate-x-0' : '-translate-x-full'} 
                    sm:translate-x-0
                    md:translate-x-0
                `} 
                style={{background: colors.sidebarBG}}
                aria-label="Sidebar"
                >
                <MyContentSidebar toggleSidebar={toggleSidebar} />
            </aside>
            <div 
                id="content-container" 
                className="flex justify-start flex-col w-full overflow-scroll pb-[36px]"
            >
                <div 
                    id="content-header" 
                    className={`flex items-center py-4 px-4 justify-between
                    ${!searchBarVisible ? 'sm:px-4 md:px-4 lg:px-40' : 'sm:px-4 md:px-4 lg:px-6'}`}
                >
                    <div className="flex items-center">
                        <button 
                            data-drawer-target="default-sidebar" 
                            data-drawer-toggle="default-sidebar" 
                            aria-controls="default-sidebar" 
                            type="button" 
                            className="
                                inline-flex
                                items-center 
                                p-2 mt-2 ms-3 
                                text-sm 
                                text-gray-500 
                                rounded-lg 
                                sm:block md:block lg:hidden 
                                hover:bg-gray-100 
                                focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600
                            "
                            onClick={() => {
                                toggleSidebar();
                                console.log('Toggling sidebar')
                            }}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                            </svg>
                        </button>

                        {!searchBarVisible ?
                            <div id="back-button">
                                <button 
                                    className="flex items-center"
                                    onClick={() => window.history.back()}
                                >
                                    <div className="flex items-center">
                                        <ArrowLeftIcon className="h-6 w-6" />
                                    </div>
                                    <div className="ml-2">
                                        Back
                                    </div>
                                </button>
                            </div>                        
                        : 
                            <div id="search-container" className="max-w-56">
                                <SearchBar text={searchTerm} />
                            </div>
                        }
                    </div>
                    <div className="flex-col cursor-pointer" onClick={() => navigate('/app/buy-credits')}>
                        <p className="font-medium">Word Credits</p>
                        <div className="flex justify-center items-center gap-[6px]">
                            <p className="text-center font-bold"
                                style={{
                                    color: colors.creditTextColor
                                }}
                            >
                                {user.availableCredits != null ? (
                                    user.availableCredits.toLocaleString()
                                ) : 'Loading...'}
                            </p>
                            <PlusCircleIcon className="h-5 w-5" />
                        </div>
                    </div>
                </div>
                <Outlet />
            </div>
        </div>
    )
}

export default MyContentPage;