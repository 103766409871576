import React from "react";

import { useState } from 'react'
import { Dialog, Disclosure, Popover } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { Link, NavLink, useLocation } from "react-router-dom";
import useAuthState from "../hooks/useAuthState";
import GradientBorderButton from "./GradientBorderButton";
import useColors from "../hooks/useColors";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

const HomeHeader: React.FC = () => {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const user = useAuthState();
  const colors = useColors();
  const location = useLocation();

  return (
    <header>
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link to="/" className="flex">
            <span className="text-[32px]">
              <span className="font-medium mr-1 text-[#B570FA]">Lazy</span>
              <span className="font-bold text-[#6A5EBF]">Write</span>
            </span>
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">

          <NavLink 
            to="/about" 
            className="leading-6 text-xl"
            style={{
              color: location.pathname === '/about' ? colors.gradientBorderStart : colors.primaryText
            }}
          >
            About Us
          </NavLink>

          <NavLink 
            to="/features" 
            className="leading-6 text-xl"
            style={{
              color: location.pathname === '/features' ? colors.gradientBorderStart : colors.primaryText
            }}
          >
            Features
          </NavLink>
          <NavLink 
            to="/prices" 
            className="leading-6 text-xl"
            style={{
              color: location.pathname === '/prices' ? colors.gradientBorderStart : colors.primaryText
            }}
          >
            Prices
          </NavLink>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {user ? (
            <NavLink 
              to={location.pathname.includes('paraphrasing') ? '/app/my-content/paraphrasing' : '/app/my-content/projects'}
            >
              <div className="flex items-center font-semibold">
                <p>My Content</p>
                <ChevronRightIcon className="h-5 w-5" />
              </div>
            </NavLink>
          ) : (
            <NavLink to="/login" className="text-[18px] font-semibold leading-6">
              Log in
            </NavLink>
          )}
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-[1000]" />
        <div className="fixed inset-0 bg-black/50 z-[500]" aria-hidden="true" />
        <Dialog.Panel 
          className="flex flex-col justify-between fixed inset-y-0 right-0 z-[1000] w-full overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
          style={{
            background: colors.sidebarBG,
            color: colors.primaryText
          }}
        >
          <div>
            <div className="flex items-center justify-between">
              <p className="text-2xl font-bold text-gray-200">Lazy Write</p>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <NavLink
                          to="/about"
                          onClick={() => setMobileMenuOpen(false)}
                        >
                          <Disclosure.Button 
                            className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-white"
                            style={{
                              background: location.pathname === '/about' ? colors.secondaryBG : 'unset'
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = colors.secondaryBG;
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = 'unset';
                            }}
                          >
                            About
                          </Disclosure.Button>
                        </NavLink>
                        <NavLink
                          to="/prices"
                          onClick={() => setMobileMenuOpen(false)}
                        >
                          <Disclosure.Button 
                            className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-white"
                            style={{
                              background: location.pathname === '/prices' ? colors.secondaryBG : 'unset'
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = colors.secondaryBG;
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = 'unset';
                            }}
                          >
                            Pricing
                          </Disclosure.Button>
                        </NavLink>
                        <NavLink
                          to="/features"
                          onClick={() => setMobileMenuOpen(false)}
                        >
                          <Disclosure.Button 
                            className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-white"
                            style={{
                              background: location.pathname === '/features' ? colors.secondaryBG : 'unset'
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = colors.secondaryBG;
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = 'unset';
                            }}
                          >
                            Features
                          </Disclosure.Button>
                        </NavLink>
                      </>
                    )}
                  </Disclosure>
                </div>
                <div className="py-6">
                {user ? (
                    <NavLink
                    to={location.pathname.includes('paraphrasing') ? '/app/my-content/paraphrasing' : '/app/my-content/projects'}
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 flex items-center rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white"
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = colors.secondaryBG;
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'unset';
                    }}
                  >
                    My Content <ChevronRightIcon className="h-5 w-5" />
                  </NavLink>
                  ) : (
                    <>
                    <NavLink
                    to="/login"
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white"
                    style={{
                      background: location.pathname === '/login' && location.search === '' ? colors.secondaryBG : 'unset'
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = colors.secondaryBG;
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'unset';
                    }}
                  >
                    Log in
                  </NavLink>
                  <NavLink
                    to="/login?type=register"
                    onClick={() => setMobileMenuOpen(false)}
                    style={{
                      background: location.pathname === '/login' && location.search === '?type=register' ? colors.secondaryBG : 'unset'
                    
                    }}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white"
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = colors.secondaryBG;
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'unset';
                    }}
                  >
                    Register
                  </NavLink>
                  </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="text-center">
              © 2024 Lazy Write.
            </p>
            <p className="text-center">
              All rights reserved.
            </p>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default HomeHeader;
