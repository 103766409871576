import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from "amazon-cognito-identity-js";
import { CognitoConfirmVerificationCodeResponse, CognitoConfirmVerificationCodeResponseCode, CognitoLoginResponse, CognitoLoginResponseCode, CognitoRegisterResponse, CognitoRegisterResponseCode, CognitoRequestVerificationCodeResponse, CognitoRequestVerificationCodeResponseCode } from "../cognitoTypes";
import store from "../storage/store";
import { userActions } from "../storage/UserSlice";
import { projectActions } from "../storage/ProjectsSlice";
import { recentlyViewedActions } from "../storage/RecentlyViewedSlice";

const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID as string,
    ClientId: process.env.REACT_APP_CLIENT_ID as string
};
const userPool = new CognitoUserPool(poolData);

const LoginService = {
    getUserPool: () => {
        return userPool;
    },
    loginWithPassword: async (email: string, password: string): Promise<CognitoLoginResponse> => {
        return new Promise((resolve, reject) => {
            try {
                const userData = {
                    Username : email,
                    Pool : userPool
                };

                const cognitoUser = new CognitoUser(userData);

                const authenticationData = {
                    Username : email,
                    Password : password,
                };

                const authenticationDetails = new AuthenticationDetails(authenticationData);

                cognitoUser.authenticateUser(authenticationDetails, {
                    onSuccess: (result) => resolve({
                        code: CognitoLoginResponseCode.SUCCESS,
                        data: result,
                    }),
                    onFailure: (error) => {
                        if(error.code === 'UserNotFoundException') {
                            reject({
                                code: CognitoLoginResponseCode.USER_NOT_FOUND,
                                data: error,
                            });
                            return;
                        }

                        if(error.code === 'NotAuthorizedException') {
                            reject({
                                code: CognitoLoginResponseCode.USER_NOT_AUTHORIZED,
                                data: error,
                            });
                            return;
                        }

                        if(error.code === 'UserNotConfirmedException') {
                            reject({
                                code: CognitoLoginResponseCode.USER_NOT_CONFIRMED,
                                data: error,
                            });
                            return;
                        }

                        reject({
                            code: CognitoLoginResponseCode.UNKNOWN_ERROR,
                            data: error,
                        });
                        return;
                    },
                    mfaRequired: (codeDeliveryDetails) => resolve({
                        code: CognitoLoginResponseCode.MFA_REQUIRED,
                        data: cognitoUser,
                    }),
                    newPasswordRequired: (userAttributes: any, requiredAttributes: any) => resolve({
                        code: CognitoLoginResponseCode.NEW_PASSWORD_REQUIRED,
                        data: {
                            userAttributes: userAttributes,
                            requiredAttributes: requiredAttributes,
                        },
                    })
                });
            } catch(error) {
                reject(error);
            }
        })
    },
    registerWithPassword: async(email: string, password: string, phoneNo: string): Promise<CognitoRegisterResponse> => {
        return new Promise((resolve, reject) => {
            try {
                const dataEmail = {
                    Name : 'email',
                    Value : email
                };

                const dataPhoneNumber = {
                    Name : 'phone_number',
                    Value : phoneNo
                };
                console.log('phoneNo', phoneNo);
                const attributeEmail = new CognitoUserAttribute(dataEmail);
                const attributePhoneNumber = new CognitoUserAttribute(dataPhoneNumber);

                const attributeList = [attributeEmail, attributePhoneNumber];

                userPool.signUp(email, password, attributeList, [], (err: any, result: any) =>{
                    if (err) {
                        console.log('err from register', err);
                        if(err.code === 'UsernameExistsException') {
                            reject({
                                code: CognitoRegisterResponseCode.USER_ALREADY_EXISTS,
                                data: err,
                            });
                            return;
                        }

                        if(err.code === 'InvalidPasswordException') {
                            reject({
                                code: CognitoRegisterResponseCode.INVALID_PASSWORD,
                                data: err,
                            });
                            return;
                        }
                        if(err.code === 'UserLambdaValidationException'){
                            reject({
                                code: CognitoRegisterResponseCode.PHONE_NUMBER_EXISTS,
                                data: err,
                            });
                            return;
                        }
                        reject({
                            code: CognitoRegisterResponseCode.UNKNOWN_ERROR,
                            data: err,
                        });
                        return;
                    }

                    console.log('result', result)

                    resolve({
                        code: CognitoRegisterResponseCode.SUCCESS,
                        data: result,
                    });

                });
            } catch(error) {
                reject(error);
            }
        });
    },
    confirmRegistration: async(email: string, confirmationCode: string): Promise<CognitoConfirmVerificationCodeResponse> => {
        return new Promise((resolve, reject) => {
            try {
                const userData = {
                    Username : email,
                    Pool : userPool
                };

                const cognitoUser = new CognitoUser(userData);

                cognitoUser.confirmRegistration(confirmationCode, true, function(err, result) {
                    if (err) {
                        if(err.code === 'ExpiredCodeException') {
                            reject({
                                code: CognitoConfirmVerificationCodeResponseCode.EXPIRED_CONFIRMATION_CODE,
                                data: err,
                            });
                            return;
                        }
                        if(err.code === 'CodeMismatchException') {
                            reject({
                                code: CognitoConfirmVerificationCodeResponseCode.INVALID_CONFIRMATION_CODE,
                                data: err,
                            });
                            return;
                        }
                        if(err.code === 'UserNotFoundException') {
                            reject({
                                code: CognitoConfirmVerificationCodeResponseCode.USER_NOT_FOUND,
                                data: err,
                            });
                            return;
                        }
                        if(err.code === 'TooManyRequestsException') {
                            reject({
                                code: CognitoConfirmVerificationCodeResponseCode.TOO_MANY_REQUESTS,
                                data: err,
                            });
                            return;
                        }
                        reject({
                            code: CognitoConfirmVerificationCodeResponseCode.UNKNOWN_ERROR,
                            data: err,
                        });
                        return;
                    }
                    resolve({
                        code: CognitoConfirmVerificationCodeResponseCode.SUCCESS,
                        data: result,
                    });
                });
            } catch(error) {
                reject(error);
            }
        });
    },
    sendMFACode: async(mfaCode: string, cognitoUser: CognitoUser): Promise<CognitoLoginResponse> => {
        return new Promise((resolve, reject) => {
            try {
                if(cognitoUser == null) {
                    reject({
                        code: CognitoLoginResponseCode.USER_NOT_FOUND,
                        data: null,
                    });
                    return;
                }

                cognitoUser?.sendMFACode(mfaCode, {
                    onSuccess: (session) => resolve({
                        code: CognitoLoginResponseCode.SUCCESS,
                        data: session,
                    }),
                    onFailure: (error) => reject({
                        code: CognitoLoginResponseCode.UNKNOWN_ERROR,
                        data: error,
                    }),
                });
            } catch(error) {
                reject(error);
            }
        });
    },
    requestVerificationCode: async(email: string): Promise<CognitoRequestVerificationCodeResponse> => {
        return new Promise((resolve, reject) => {
            try {
                const cognitoUser = new CognitoUser({
                    Username : email,
                    Pool : userPool
                });
                
                if (cognitoUser != null) {
                    cognitoUser.resendConfirmationCode(function(error: any, result) {
                        if(error) {
                            if (error.code === 'CodeDeliveryFailureException') {
                                console.error(error);
                                reject({
                                    code: CognitoRequestVerificationCodeResponseCode.FAILED_TO_SEND_CODE,
                                    data: error,
                                });
                                return;
                            }
                            if (error.code === 'LimitExceededException') {
                                console.error(error);
                                reject({
                                    code: CognitoRequestVerificationCodeResponseCode.TOO_MANY_REQUESTS,
                                    data: error,
                                });
                                return;
                            }
                            if (error.code === 'TooManyRequestsException') {
                                console.error(error);
                                reject({
                                    code: CognitoRequestVerificationCodeResponseCode.TOO_MANY_REQUESTS,
                                    data: error,
                                });
                                return;
                            }
                            if (error.code === 'UserNotFoundException') {
                                console.error(error);
                                reject({
                                    code: CognitoRequestVerificationCodeResponseCode.USER_NOT_FOUND,
                                    data: error,
                                });
                                return;
                            }
                            reject({
                                code: CognitoRequestVerificationCodeResponseCode.UNKNOWN_ERROR,
                                data: error,
                            
                            })
                            return;
                        }

                        console.log('Resend confirmation code successful:', result);
                        resolve({
                            code: CognitoRequestVerificationCodeResponseCode.SUCCESS,
                            data: result,
                        })
                    });
                } else {
                    reject('No user found');
                    return;
                }
            } catch(error) {
                reject(error);
            }
        });
    },
    getUserSession: async(): Promise<any> => {
        return new Promise((resolve, reject) => {
            try {
                const cognitoUser = userPool.getCurrentUser();
                if (cognitoUser != null) {
                    cognitoUser.getSession((err: any, session: any) => {
                        if (err) {
                            reject(err);
                            return;
                        }
                        resolve(session);
                        return;
                    });
                }
            } catch(error) {
                reject(error);
                return
            }
        });
    },
    getUserProperties: async(): Promise<any> => {
        return new Promise((resolve, reject) => {
            try {
                const cognitoUser = userPool.getCurrentUser();
                if (cognitoUser != null) {
                    cognitoUser.getUserAttributes((err, result) => {
                        if (err) {
                            reject(err);
                            return;
                        }
                        resolve(result);
                    });
                } else {
                    reject('No user found');
                    return;
                }
            } catch(error) {
                console.log('error getting user properties', error)
                reject(error);
            }
        });

    },
    logout: () => {
        return new Promise((resolve, reject) => {
            try {

                store.dispatch(userActions.clearUser());
                store.dispatch(projectActions.clearProjects());
                store.dispatch(recentlyViewedActions.clearRecentlyViewed());

                const cognitoUser = userPool.getCurrentUser();
                if (cognitoUser != null) {
                    cognitoUser.signOut();
                }
                resolve(true);
            } catch(error) {
                console.log('error', error)
                reject(false);
            }
        });
    }
};

export default LoginService;
