import { useEffect, useState } from "react";
import RegisterComponent from "../../components/RegisterComponent";
import { PageSetup } from "../../globalTypes";
import LoginComponent from "../../components/LoginComponent";
import { useSearchParams } from "react-router-dom";

const Login: React.FC = () => {

    const [pageSetup, setPageSetup]: [PageSetup, any] = useState<PageSetup>(PageSetup.LOGIN);

    const [queryParameters] = useSearchParams()

    useEffect(() => {
        console.log(queryParameters);
        if (queryParameters.get('type') === 'register') {
            setPageSetup(PageSetup.REGISTER);
        } else {
            setPageSetup(PageSetup.LOGIN);
        }
    }, [queryParameters]);

    function changePageSetup(pSetup: PageSetup) {
        setPageSetup(pSetup);
    }

    return (
        <div className="flex flex-col w-full justify-center items-center">
            {pageSetup === PageSetup.LOGIN && 
                <LoginComponent
                    changePageSetup={changePageSetup}
                />
            }
            {pageSetup === PageSetup.REGISTER && 
                <RegisterComponent 
                    changePageSetup={changePageSetup}
                />
            }
            {/* <div className="flex flex-col sm:w-2/3 md:w-1/2 lg:w-1/3 p-4">
                <p className="text-center mb-2">Or continue with</p>
                <div className="flex flex-row justify-between">
                <button className="bg-red-500 text-white rounded p-2">
                    Google
                </button>
                <button className="bg-blue-500 text-white rounded p-2">
                    Facebook
                </button>
                </div>
            </div> */}
        </div>
    )

}

export default Login;