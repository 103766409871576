import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { FolderIcon } from "@heroicons/react/20/solid";
import { XCircleIcon } from "@heroicons/react/24/outline";
import useColors from "../hooks/useColors";
// import ReactSlider from "react-slider";
import './CreateModal.css';

type CreateModalProps = {
    presetTitle?: string | null;
    presetDescription?: string | null;
    presetWordCount?: number | null;
    isOpen: boolean;
    toggleModal: () => void;
    onSubmit: (name: string, description?: string, wordCount?: number) => Promise<boolean>;
    onCancel?: () => Promise<void>;
    type: 'project' | 'content' | 'suggestion' | 'paraphrase';
}

type Defaults = {
    header: string,
    titlePlaceholder?: string,
    descriptionPlaceholder?: string,
    submitText?: string,
}

const CreateModal: React.FC<CreateModalProps> = (props) => {

    const colors = useColors();

    let defaults: Defaults = {
        header: 'Create new project',
        titlePlaceholder: 'Project title',
        descriptionPlaceholder: 'Project description',
        submitText: 'Create'
    }

    if(props.type === 'project') {
        defaults = {
            header: 'Create new project',
            titlePlaceholder: 'Project title',
            descriptionPlaceholder: 'Project description',
            submitText: 'Create'
        }
    } else if(props.type === 'content') {
        defaults = {
            header: 'Create new content',
            titlePlaceholder: 'Content title',
            descriptionPlaceholder: 'Content description',
            submitText: 'Create'
        }
    } else if(props.type === 'suggestion') {
        defaults = {
            header: 'Create new content from suggestion',
            titlePlaceholder: 'Content title',
            descriptionPlaceholder: 'Content description',
            submitText: 'Generate'
        }

    } else if(props.type === 'paraphrase'){
        defaults = {
            header: 'Paraphrase file',
            titlePlaceholder: 'File name',
            submitText: 'Create'
        }
    } else {
        defaults = {
            header: 'Create new project',
            titlePlaceholder: 'Project title',
            descriptionPlaceholder: 'Project description',
        }
    }

    useEffect(() => {
        setTitle(props.presetTitle || title);
        setDescription(props.presetDescription || description)
        setWordCount(props.presetWordCount || 500);
    }, [props]);

    const [title, setTitle] = useState<string>(props.presetTitle || '');
    const [description, setDescription] = useState<string>(props.presetDescription || '');
    const [wordCount, setWordCount] = useState<number>(0);

    function submit() {
        // validate form data
        props.onSubmit(title, description, wordCount);

        setTitle('');
        setDescription('');
        setWordCount(500);

        //close modal
        props.toggleModal();
    }

    function cancel() {
        setTitle('');
        setDescription('');
        props.toggleModal();
        props.onCancel && props.onCancel();
    }

    return (
        <Transition appear show={props.isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => cancel()}>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black opacity-30" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                        <Dialog.Panel 
                            className="
                                w-full
                                h-3/4 
                                absolute 
                                bottom-0 
                                transform 
                                overflow-hidden 
                                rounded-2xl 
                                text-left 
                                align-middle 
                                shadow-xl 
                                transition-all 
                                md:w-1/2 md:h-auto md:bottom-auto
                                lg:w-1/2 lg:h-auto lg:bottom-auto
                            "
                            style={{background: colors.secondaryBG}}
                        >
                            <Dialog.Title className="flex justify-between items-center p-4">
                                <div className="flex items-center">
                                    <FolderIcon className="h-8 w-8 text-indigo-500 mr-2" />
                                    <div>{defaults.header}</div>
                                </div>
                                <XCircleIcon 
                                    className="h-6 w-6 text-gray-400 hover:text-gray-700 cursor-pointer" 
                                    onClick={() => cancel()} 
                                />
                            </Dialog.Title>

                            <Dialog.Description className="p-3">
                                <div id="project-name" className="mb-2 font-medium">
                                    Name
                                </div>
                                <input 
                                    type="text" 
                                    placeholder={defaults.titlePlaceholder || ''}
                                    className="w-full rounded-[8px] p-2 mb-2 input-bg"
                                    onChange={(e) => setTitle(e.target.value)}
                                    value={title}
                                    maxLength={75}
                                />
                                {props.type !== 'paraphrase' ? 
                                    <>
                                    <div id="project-description" className="mb-2 font-medium">
                                        Description <span className="text-gray-400 text-sm">(max 250 characters)</span>
                                    </div>
                                    <textarea 
                                        placeholder={defaults.descriptionPlaceholder || ''}
                                        className="w-full rounded-[8px] p-2 input-bg"
                                        rows={5}
                                        onChange={(e) => setDescription(e.target.value)}
                                        value={description}
                                        maxLength={250}
                                    />
                                    </>
                                : null}
                                {props.type === 'content' && (
                                    <div>
                                        <div id="project-wordCount" className="mb-2 font-medium">
                                            Word Count
                                        </div>
                                        <div className="mb-2 font-medium">
                                            <input 
                                                type="text" 
                                                inputMode="numeric"
                                                onChange={(e) => {
                                                    if(Number.isInteger(parseInt(e.target.value))) {
                                                        setWordCount(parseInt(e.target.value))
                                                    } else {
                                                        setWordCount(0);
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    console.log(typeof e.target.value);
                                                    if(!Number.isInteger(parseInt(e.target.value)) || parseInt(e.target.value) < 50) {
                                                        setWordCount(50);
                                                    }
                                                    if(parseInt(e.target.value) > 10000) {
                                                        setWordCount(10000);
                                                    }
                                                }}
                                                className="w-[100px] p-1 text-center rounded-[8px] input-bg"
                                                value={wordCount}
                                            ></input>
                                        </div>
                                    </div>
                                )}
                            </Dialog.Description>
                            <div className="flex justify-end pr-2 pb-2">
                                <button 
                                    className="bg-gray-200 text-gray-700 p-2 rounded hover:bg-gray-300 mr-2 cursor-pointer"
                                    onClick={() => cancel()}
                                >
                                    Cancel
                                </button>
                                <button 
                                    onClick={() => submit()}
                                    className="bg-indigo-500 text-white p-2 rounded hover:bg-indigo-700 cursor-pointer"
                                >
                                    {defaults.submitText}
                                </button>
                            </div>
                        </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );

}

export default CreateModal;
