const Features = () => {

    const contentWriter = [{
        title: 'GPT-4o powered writing',
        content: 'GPT-4 is the latest version of the GPT series of language models. It is the most powerful language model ever created and is capable of generating human-like text.'
    },
    {
        title: "Scalable content generation",
        content: "Generate multiple pieces of content at once with Lazy Write's suggested content generation feature. You can generate content for multiple projects simultaneously, saving you time and effort."
    },
    {
        title: "Unlimited projects & content storage",
        content: "Create as many projects as you like and store as much content as you need. Lazy Write has no limits on the number of projects or the amount of content you can store."
    },
    {
        title: "Customizable content generation",
        content: "Customize the content generated by Lazy Write to suit your needs. You can choose the tone, style, and length of the content, as well as add your own keywords and phrases."
    }, 
    {
        title: "Ad free experience",
        content: "Enjoy an ad-free experience with Lazy Write. Our platform is free of distracting ads, so you can focus on creating great content."
    }, 
    {
        title: "Secure & private",
        content: "Lazy Write takes data privacy and security seriously. Your data is encrypted and stored securely on our servers. We do not share your data with any third parties."
    },
    {
        title: "Superb customer support",
        content: "Get help when you need it with Lazy Write's customer support team. Our team is available to assist you with any questions or issues you may have."
    }]

    const paraphrasing = [{
        title: 'GPT-4o powered paraphrasing',
        content: 'GPT-4 is the latest version of the GPT series of language models. It is the most powerful language model ever created and is capable of generating human-like text.'
    }, {
        title: 'Transparent payment system',
        content: 'Use word credits to ensure you only pay for the words you need. We only charge for the original content you input - the paraphrased text is free!'
    }, {
        title: 'Easy, fast & accurate',
        content: 'Paraphrasing with Lazy Write is easy, fast, and accurate. Simply input your text, select the target word count you require, and let Lazy Write do the rest.'
    }]

    const colorMap = ['#1b273b', '#25365b', '#35457d', '#4c529e', '#6a5ebf', '#8d68de', '#b570fa']
    const colorMapParaphrasing = ['#008ba0', '#00a3a4', '#00bca1']

    return (
        <div className="flex flex-wrap justify-center gap-[24px] w-full h-full">
            <div className="w-full px-[24px] md:px-0 md:w-[30%] lg:w-[40%] mb-[36px]">
                <h1 className="text-2xl font-bold text-center text-white mb-[12px]">Content Writer</h1>
                <div className="flex justify-center">
                    <div>
                    {contentWriter.map((feature, index) => (
                        <div key={index} className="border rounded-[8px] my-[12px]"
                            style={{
                                borderColor: '#1B263B'
                            }}
                        >
                            <div 
                                className={`flex justify-between items-center p-[12px] cursor-pointer
                                ${index === 0 ? 'rounded-t-[8px]' : ''}`}
                                style={{
                                    background: colorMap[index]
                                }}
                            >
                                <h2 className="text-lg font-medium">
                                    {feature.title}
                                </h2>
                                <div className="w-[12px] h-[12px] bg-white rounded-full animate-pulse ml-[8px]"></div>
                            </div>
                            <div 
                                className={`${index === contentWriter.length - 1 ? 'rounded-b-[8px]' : ''}`}
                                style={{
                                background: colorMap[index]
                            }}>
                                <p className="p-[12px]">
                                    {feature.content}
                                </p>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
            <div className="w-full px-[24px] md:px-0 md:w-[30%] lg:w-[40%] mb-[36px]">
                <h1 className="text-2xl font-bold text-center text-white mb-[12px]">Paraphrasing</h1>
                <div className="flex justify-center">
                    <div>
                    {paraphrasing.map((feature, index) => (
                        <div key={index} className="border rounded-[8px] my-[12px]"
                            style={{
                                borderColor: '#1B263B'
                            }}
                        >
                            <div 
                                className={`flex justify-between items-center p-[12px] cursor-pointer
                                ${index === 0 ? 'rounded-t-[8px]' : ''}`}
                                style={{
                                    background: colorMapParaphrasing[index]
                                }}
                            >
                                <h2 className="text-lg font-medium">
                                    {feature.title}
                                </h2>
                                <div className="w-[12px] h-[12px] bg-white rounded-full animate-pulse ml-[8px]"></div>
                            </div>
                            <div 
                                className={`${index === paraphrasing.length - 1 ? 'rounded-b-[8px]' : ''}`}
                                style={{
                                background: colorMapParaphrasing[index]
                            }}>
                                <p className="p-[12px]">
                                    {feature.content}
                                </p>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Features;