import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Content, Project } from '../globalTypes';
import { recentlyViewedActions } from './RecentlyViewedSlice';
import store from './store';

export const ProjectsSlice = createSlice({
  name: 'projects',
  initialState: {},
  reducers: {
    setProjects: (state, action) => {
      console.log('setProjects', action.payload);
      return action.payload;
    },
    addProject: (state: any, action: PayloadAction<{
      projectID: string,
      title: string,
      description: string,
      content: {[key: string]: Content},
      updatedAt: string,
      createdAt: string,
    }>) => {
      state[action.payload.projectID] = {
        title: action.payload.title,
        description: action.payload.description,
        content: action.payload.content,
        updatedAt: action.payload.updatedAt,
        createdAt: action.payload.createdAt,
      };
    },
    updateProject: (state: any, action: PayloadAction<{
      projectID: string,
      properties: Partial<Project>
    }>) => {
      for(const property in action.payload.properties) {
        // @ts-ignore
        state[action.payload.projectID][property] = action.payload.properties[property];
      }
    },
    deleteProject: (state: any, action: PayloadAction<{
      projectID: string,
    }>) => {
      delete state[action.payload.projectID];
    },
    addContent: (state: any, action: PayloadAction<{
      projectID: string,
      contentID: string,
      title: string,
      description: string,
      body: string,
      createdAt: string,
      updatedAt: string,
      wordCount: number,
    }>) => {
      state[action.payload.projectID].content[action.payload.contentID] = {
        title: action.payload.title,
        description: action.payload.description,
        body: action.payload.body,
        createdAt: action.payload.createdAt,
        updatedAt: action.payload.updatedAt,
        wordCount: action.payload.wordCount
      };
    },
    updateContent: (state: any, action: PayloadAction<{
      projectID: string,
      contentID: string,
      properties: Partial<Content>
    }>) => {

      for(const property in action.payload.properties) {
        // @ts-ignore
        state[action.payload.projectID].content[action.payload.contentID][property] = action.payload.properties[property];
      }
    },
    deleteContent: (state: any, action: PayloadAction<{
      projectID: string,
      contentID: string,
    }>) => {
      delete state[action.payload.projectID].content[action.payload.contentID];
    },
    updateContentSuggestion: (state: any, action: PayloadAction<{
      projectID: string,
      contentID: string,
      suggestionIndex: number,
      property: string,
      value: any,
    }>) => {

      try {
        const suggestion = state[action.payload.projectID].content[action.payload.contentID].suggestions[action.payload.suggestionIndex];
        suggestion[action.payload.property] = action.payload.value;
        state[action.payload.projectID].content[action.payload.contentID].suggestions[action.payload.suggestionIndex] = suggestion;

        // suggestion[action.payload.property] = action.payload.value;
        // state[action.payload.projectID].content[action.payload.contentID].suggestions = suggestions;   

      } catch(e) {
        console.log('error', e);
      }
    },
    clearProjects: (state) => {
      return {};
    }
  },
})

// Action creators are generated for each case reducer function
export const projectActions = { 
  ...ProjectsSlice.actions,
}
export default ProjectsSlice.reducer;