import { useSelector } from "react-redux";
import useColors from "../hooks/useColors";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { PencilSquareIcon, XCircleIcon } from "@heroicons/react/24/outline";

type ModifyModalProps = {
    isOpen: boolean,
    toggleModal: () => void,
    onSubmit: (title: string, description: string, wordCount?: number | null) => void,
    title: string,
    description: string,
    wordCount?: number | null,
    type: 'project' | 'content' | 'paraphrase',
}

const ModifyModal: React.FC<ModifyModalProps> = (props) => {

    const colors = useColors();

    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [wordCount, setWordCount] = useState<number | null>(null);

    useEffect(() => {
        setTitle(props.title);
        setDescription(props.description);
        setWordCount(props.wordCount || null);
    }, [props.title, props.description, props.wordCount]);

    function confirm() {
        props.onSubmit(title, description, wordCount);
        props.toggleModal();
    }

    function cancel() {
        setTitle(props.title);
        setDescription(props.description);
        setWordCount(props.wordCount || null);
        props.toggleModal();
    }

    return(
        <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog className="z-10" open={props.isOpen} onClose={() => props.toggleModal()}>

            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black opacity-30" />
            </Transition.Child>

            <Dialog.Panel 
                className="
                    w-full
                    absolute 
                    bottom-0 
                    transform 
                    overflow-hidden 
                    rounded-2xl 
                    text-left 
                    align-middle 
                    shadow-xl 
                    transition-all 
                    md:w-1/2 md:h-max md:m-auto md:top-0 md:bottom-0 md:left-0 md:right-0
                    lg:w-1/2 lg:h-max md:m-auto md:top-0 md:bottom-0 md:left-0 md:right-0
                "
                style={{background: colors.secondaryBG}}
            >
                <Dialog.Title 
                    className="flex justify-between items-center p-4"
                >
                    <div className="flex items-center">
                        <PencilSquareIcon className="h-8 w-8 text-indigo-500 mr-2" />
                        <p>
                            Modify <span className="font-medium">{props.title}</span>
                        </p>
                    </div>
                    <XCircleIcon 
                        className="h-6 w-6 text-gray-400 hover:text-gray-700 cursor-pointer" 
                        onClick={() => cancel()} 
                    />
                </Dialog.Title>
                <Dialog.Description className="p-3">

                    <div id="project-title" className="mb-2 font-medium">
                        Title
                    </div>
                    <input 
                        type="text" 
                        className="w-full rounded-[8px] p-2 mb-2 input-bg"
                        placeholder="Title"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                    />
                    {props.type !== 'paraphrase' && (
                        <>
                        <div id="project-description" className="mb-2 font-medium">
                            Description <span className="text-gray-400 text-sm">(max 250 characters)</span>
                        </div>
                        <textarea 
                            placeholder={'Description'}
                            className="w-full rounded-[8px] p-2 input-bg"
                            rows={5}
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            maxLength={250}
                        />
                        </>
                    )}
                    
                    {props.type === 'content' ? (
                        <div>
                            <div id="project-wordCount" className="mb-2 font-medium">
                                Word Count
                            </div>
                            <div className="mb-2 font-medium">
                                <input 
                                    type="text" 
                                    inputMode="numeric"
                                    onChange={(e) => {
                                        if(Number.isInteger(parseInt(e.target.value))) {
                                            setWordCount(parseInt(e.target.value))
                                        } else {
                                            setWordCount(0);
                                        }
                                    }}
                                    onBlur={(e) => {
                                        if(!Number.isInteger(parseInt(e.target.value)) || parseInt(e.target.value) < 50) {
                                            setWordCount(50);
                                        }
                                        if(parseInt(e.target.value) > 10000) {
                                            setWordCount(10000);
                                        }
                                    }}
                                    className="w-[100px] p-1 text-center rounded-[8px] input-bg"
                                    value={wordCount || 0}
                                ></input>
                            </div>
                        </div>
                    ) : null}
                    
                </Dialog.Description>
        
                <div className="flex justify-end pr-2 pb-2">
                    <button 
                        className="bg-gray-200 text-gray-700 p-2 rounded hover:bg-gray-300 mr-2 cursor-pointer"
                        onClick={() => cancel()}
                    >
                        Cancel
                    </button>
                    <button 
                        onClick={() => confirm()}
                        className="bg-indigo-500 text-white p-2 rounded hover:bg-indigo-700 cursor-pointer"
                    >
                        Save
                    </button>
                </div>
            </Dialog.Panel>
        </Dialog>
    </Transition>
    )
}

export default ModifyModal;