import { GetCheckoutSessionResponse, GetCheckoutSessionResponseCode } from "../stripeTypes";
import LoginService from "./LoginService";

const CheckoutService = {
    getCheckoutSession: async (sessionID: string): Promise<GetCheckoutSessionResponse> => {
        try {

            const userSession = await LoginService.getUserSession();
            const response: any = await fetch('https://myok4hinbg.execute-api.us-east-2.amazonaws.com/getStripeSession', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userSession.getIdToken().getJwtToken(),
                },
                body: JSON.stringify({sessionID})
            });

            const data = await response.json();
            switch(data.message) {
                case 'card_error':
                    return {
                        code: GetCheckoutSessionResponseCode.CARD_ERROR,
                        data: data,
                    }
                case 'idempotency_error':
                    return {
                        code: GetCheckoutSessionResponseCode.IDEMPOTENCY_ERROR,
                        data: data,
                    }
                case 'invalid_request_error':
                    return {
                        code: GetCheckoutSessionResponseCode.INVALID_SESSION_ERROR,
                        data: data,
                    }
                case 'api_error':
                    return {
                        code: GetCheckoutSessionResponseCode.API_ERROR,
                        data: data,
                    }
                default:
                    return {
                        code: GetCheckoutSessionResponseCode.SUCCESS,
                        data: data,
                    }
            }

        } catch (error: any) {
            console.log('error fetching stripe checkout', error);
            return {
                code: GetCheckoutSessionResponseCode.UNKNOWN_ERROR,
                data: null,
            }
        }
    }
}

export default CheckoutService;