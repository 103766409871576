import { CSSProperties, useCallback, useEffect, useState } from "react";
import List from "../../components/List";
import { PlusIcon } from "@heroicons/react/24/outline";
import CreateModal from "../../components/CreateModal";
import { useSelector } from "react-redux";
import { projectActions } from "../../storage/ProjectsSlice";
import { useNavigate } from "react-router-dom";
import store from "../../storage/store";
import { ReactComponent as CreateNew } from "../../theme/img/card-icon/create-new.svg";
import useColors from "../../hooks/useColors";
import ContentService from "../../content-service/ContentService";
import GradientBorderButton from "../../components/GradientBorderButton";

const Projects: React.FC = () => {

    const colors = useColors();

    const [showCreateProjectModal, setShowCreateProjectModal] = useState<boolean>(false);
    const projects = useSelector((state: any) => state.projects);
    const projectFieldNames = ['title', 'description'];
    const navigate = useNavigate();

    const createProject = useCallback(async (title: string, description?: string) => {

        ContentService.addProject({
            title: title,
            description: description ?? ''
        });

        console.log('projects', projects);
        return true;

    }, [projects]);

    const navigateToProject = useCallback((projectID: number) => {
        navigate(`/app/my-content/projects/${projectID}`);
    }, [projects]);

    return (
        <div id="projects-container" className="px-4 sm:px-4 md:px-4 lg:px-6">
            <CreateModal 
                isOpen={showCreateProjectModal} 
                toggleModal={() => setShowCreateProjectModal(!showCreateProjectModal)} 
                onSubmit={createProject}
                type="project"
            />

            <div id="projects-header" className="mb-6">
                <div id="title" className="flex justify-start font-bold text-3xl mb-9">
                    Projects
                </div>
                <GradientBorderButton
                    onClick={() => setShowCreateProjectModal(!showCreateProjectModal)}
                >
                    New Project
                </GradientBorderButton>
            </div>
            <div id="projects-list">
                {projects && Object.keys(projects).length > 0 && (
                    <List 
                        type="project"
                        fieldNames={projectFieldNames}
                        items={projects} 
                        onRowPress={navigateToProject}
                    />
                )}
            </div>
        </div>
    )

};

export default Projects;