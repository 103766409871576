import { useNavigate } from "react-router-dom";
import CheckoutForm from "../../components/CheckoutForm";

const BuyCreditsPage = () => {

    const navigate = useNavigate();

    return (
        <>
        <h1 
            className="pl-[24px] lg:pl-[120px] pt-[24px] text-2xl"
            onClick={() => navigate("/app/my-content/projects/", { replace: true })}
        >
            <span className="font-medium mr-1">Lazy</span>
            <span className="font-bold">Write</span>
        </h1>
        <h1 className="text-xl font-medium text-center my-[24px]">Buy Credits</h1>
        <div id="checkout-container" className="w-full h-full">
            <CheckoutForm />
        </div>
        </>
    )

}

export default BuyCreditsPage;
