import { useForm } from "react-hook-form";
import useColors from "../hooks/useColors";
import emailjs from '@emailjs/browser';
import { useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

type ContactForm = {
    name: string;
    email: string;
    message: string;
}

const ContactUsForm: React.FC = () => {

    const colors = useColors();
    
    const showToastError = (message: string) => {
        toast.error(message, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const [hideOnSuccess, setHideOnSuccess] = useState(false);

    const formRef = useRef<HTMLFormElement>(null);

    const onSubmit = (data: ContactForm) => {
        emailjs.sendForm('service_6bdpq9m', 'template_f4j1f8c', formRef?.current ?? '', {
            publicKey: '4r2fFdhtdBIGOrHMM',
        }).then(() => {
          setHideOnSuccess(true);
        },
        (error) => {
          showToastError('Error sending email, please try again');
        },
      );
    }

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<ContactForm>();

    return (
        <>
        <ToastContainer />
        {hideOnSuccess ? (
            <p className="text-green-600 text-center font-bold my-[36px]">Email sent successfully, we'll be in touch shortly</p>
        ) : (
            <form ref={formRef} onSubmit={handleSubmit(onSubmit)} className="w-full">
                <p className="flex justify-center my-[12px] font-bold text-[28px] lg:text-[40px] z-30">
                    Contact us
                </p>
                <p className='font-medium'>Name</p>
                <input 
                    {...register('name', { required: true })}
                    className="w-full rounded-[8px] p-2 mb-2 input-bg"
                />
                {errors.name && <p className="text-red-600 mt-2">Name is required</p>}
                <p className='font-medium'>Email</p>
                <input 
                    {...register('email', { 
                        pattern: {
                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                            message: 'Email isn\'t valid' // JS only: <p>error message</p> TS only support string
                        },
                        required: true 
                    })}
                    className="w-full rounded-[8px] p-2 mb-2 input-bg"
                />
                {errors.email && <p className="text-red-600 mt-2">{errors.email.message}</p>}
                <p className='font-medium'>Message</p>
                <textarea 
                    {...register('message', { required: true })}
                    className="w-full rounded-[8px] p-2 mb-2 input-bg"
                />
                {errors.message && <p className="text-red-600 mt-2">Message is required</p>}
                <div className="flex justify-end">
                    <button 
                        className="rounded-[8px] border px-4 py-2 mb-2"
                        style={{
                            background: colors.sidebarBG,
                            color: colors.primaryText,
                            borderColor: colors.primaryBorder
                        }}
                    >
                        Submit
                    </button>
                </div>
            </form>
        )}
        </>
    )

}

export default ContactUsForm;