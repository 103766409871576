import { SubmitHandler, useForm } from "react-hook-form";
import LoginService from "../auth/LoginService";
import { CognitoConfirmVerificationCodeResponse, CognitoConfirmVerificationCodeResponseCode, CognitoLoginResponse, CognitoLoginResponseCode, CognitoRequestVerificationCodeResponse, CognitoRequestVerificationCodeResponseCode } from "../cognitoTypes";
import { PageSetup } from "../globalTypes";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ContentService from "../content-service/ContentService";
import store from "../storage/store";
import { ToastContainer, toast } from 'react-toastify';
import { userActions } from "../storage/UserSlice";
import { projectActions } from "../storage/ProjectsSlice";
import { recentlyViewedActions } from "../storage/RecentlyViewedSlice";
import ParaphrasingService from "../content-service/ParaphrasingService";
import { paraphrasingActions } from "../storage/ParaphrasingSlice";

type LoginForm = {
    email: string;
    password: string;
    confirmationCode: string;
}

const LoginComponent: React.FC<{
    changePageSetup: any,
}> = ({
    changePageSetup
}) => {

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<LoginForm>();

    const navigate = useNavigate();
    const [awaitingConfirmation, setAwaitingConfirmation] = useState<boolean>(false);
    const [hidePassword, setHidePassword] = useState<boolean>(true);

    const fetchUserData = async() => {

        const user = await ContentService.getUser();
        const projects = await ContentService.fetchProjects();
        const recentlyViewed = await ContentService.fetchRecentlyViewed();
        const paraphrasingFiles = await ParaphrasingService.fetchParaphrasingFiles();

        if(user) {
            store.dispatch(userActions.setUser(user));
        }

        if(projects) {
            store.dispatch(projectActions.setProjects(projects));
        }

        if(recentlyViewed) {
            store.dispatch(recentlyViewedActions.setRecentlyViewed(recentlyViewed));
        }

        if(paraphrasingFiles) {
            store.dispatch(paraphrasingActions.setParaphrasingFiles(paraphrasingFiles));
        }

    }

    const onSignInSubmit: SubmitHandler<LoginForm> = async(data: any) => {
        try {
            const loginResponse: CognitoLoginResponse = await LoginService.loginWithPassword(
                data.email, 
                data.password
            );

            if(loginResponse.code === CognitoLoginResponseCode.SUCCESS) {
                await fetchUserData();
                changePageSetup(PageSetup.LOGIN);
                navigate('/app/my-content/projects');
                return;
            }

            if(loginResponse.code === CognitoLoginResponseCode.MFA_REQUIRED) {
                console.log('loginResponse', loginResponse)
                const code = prompt('Please enter the code sent to your phone');
                if(code) {
                    const mfaResponse = await LoginService.sendMFACode(code, loginResponse.data);
                    if(mfaResponse.code === CognitoLoginResponseCode.SUCCESS) {
                        await fetchUserData();
                        changePageSetup(PageSetup.LOGIN);
                        navigate('/app/my-content/projects');
                        return;
                    }
                }
            }
            
        } catch (error: any | CognitoLoginResponse) {
            console.log('loginError', error);

            if(error.code === CognitoLoginResponseCode.USER_NOT_CONFIRMED) {
                requestVerificationCode();
                setAwaitingConfirmation(true);
                return;
            }
            if(error.code === CognitoLoginResponseCode.USER_NOT_FOUND || error.code === CognitoLoginResponseCode.USER_NOT_AUTHORIZED) {
                showToastError('The email or password you entered is incorrect');
                return;
            }

            if(error.code === CognitoLoginResponseCode.UNKNOWN_ERROR) {
                showToastError('Unknown error, please contact support');
                return;
            }

            showToastError('Failed to login, please try again');
            return;
        }
    }

    const requestVerificationCode = async() => {
        try {
            const email = watch('email');
            const verificationCodeResponse: CognitoRequestVerificationCodeResponse = await LoginService.requestVerificationCode(email);

            if(verificationCodeResponse.code === CognitoRequestVerificationCodeResponseCode.SUCCESS) {
                return;
            } else {
                showToastError('Failed to request verification code, please try again');
                console.log('verificationCodeResponse', verificationCodeResponse);
            }
        } catch (error: any | CognitoRequestVerificationCodeResponse) {
            // FAILED TO REQUEST VERIFICATION CODE
            if(error.code === CognitoRequestVerificationCodeResponseCode.TOO_MANY_REQUESTS) {
                showToastError('Too many requests, please try again later');
                return;
            }

            if(error.code === CognitoRequestVerificationCodeResponseCode.FAILED_TO_SEND_CODE) {
                showToastError('Failed to send code, please press resend');
                return;
            }
            showToastError('Failed to request verification code');
            console.log('verificationCodeError', error);
            return;
        }
    }

    const confirmVerificationCode = async() => {
        const email = watch('email');
        const password = watch('password');
        const code = watch('confirmationCode');
        try {
            const verificationCodeResponse = await LoginService.confirmRegistration(email, code);
            if(verificationCodeResponse.code === CognitoConfirmVerificationCodeResponseCode.SUCCESS) {
                try {
                    const loginResponse = await LoginService.loginWithPassword(email, password);
                    if(loginResponse.code === CognitoLoginResponseCode.SUCCESS) {
                        await fetchUserData();
                        changePageSetup(PageSetup.LOGIN);
                        navigate('/app/my-content/projects');
                        return;
                    }
                } catch (error: any | CognitoLoginResponse) {
                    showToastError('Failed to login after confirming code, please try to login again');
                    return;
                }
            } else {
                showToastError('Failed to verify confirmation code, please try again');
                console.log('verificationCodeResponse', verificationCodeResponse);
                return;
            }
        } catch (error: any | CognitoConfirmVerificationCodeResponse) {
            if(error.code === CognitoConfirmVerificationCodeResponseCode.INVALID_CONFIRMATION_CODE) {
                showToastError('Invalid confirmation code');
                return;
            }

            if(error.code === CognitoConfirmVerificationCodeResponseCode.EXPIRED_CONFIRMATION_CODE) {
                showToastError('Confirmation code has expired, please request a new one');
                return;
            }

            showToastError('Failed to verify confirmation code');
            console.log('verificationCodeError', error);
        }
    }

    const showToastError = (message: string) => {
        toast.error(message, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    return (
        <div 
            className="flex flex-col w-full md:w-1/2 lg:w-1/3 rounded shadow p-4"
        >
            <ToastContainer />
            {awaitingConfirmation ? (
                <div className="flex flex-col">
                    <h2 className="mt-2">Confirmation Code</h2>
                    <p className="text-sm">Please enter the confirmation code sent to your email</p>
                    <input
                        {...register('confirmationCode', {
                            required: 'Confirmation code is required',
                        })}
                        className="w-full rounded-[8px] p-2 mb-2 input-bg"
                        type="text"
                    />
                    <button 
                        className="bg-blue-500 text-white rounded p-2 w-full"
                        onClick={confirmVerificationCode}
                    >
                        Confirm
                    </button>
                    <p className="text-center mt-2">Didn't receive the code? <span onClick={requestVerificationCode} className="text-blue-500 cursor-pointer">Resend</span></p>
                </div>
            ) : (
                <form onSubmit={handleSubmit(onSignInSubmit)}>
                    <h1 className="text-center text-2xl mb-4">Login</h1>
                        <>
                        <h2>Email</h2>  
                        <input 
                            className="w-full rounded-[8px] p-2 mb-2 input-bg"
                            type="text" 
                            {...register('email', {
                                pattern: {
                                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                    message: 'Please enter a valid email',
                                },
                                required: 'Email is required',
                            })}
                        />
                        <div className="flex flex-row justify-between">
                            <h2>Password</h2>
                            <p className="text-blue-500 cursor-pointer text-sm text-right" onClick={() => setHidePassword(!hidePassword)}>Show Password</p>
                        </div>
                        <input 
                            className="w-full rounded-[8px] p-2 mb-2 input-bg"
                            type={hidePassword ? 'password' : 'text'}
                            {...register('password', {
                                required: 'Password is required',
                            })}
                        />
                        <button 
                            className="bg-blue-500 text-white rounded p-2 mt-2 w-full"
                            onClick={() => handleSubmit(onSignInSubmit)}
                        >
                            Login
                        </button>
                        <p className="text-center mt-[16px]">Don't have an account? <span onClick={() => changePageSetup(PageSetup.REGISTER)} className="text-blue-500 cursor-pointer">Register</span></p>
                        </>
                </form>
            )}
        </div>
    )
}

export default LoginComponent;