import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type UserSliceState = {
    userId: string
    usedCredits: number
    availableCredits: number,
    transactions: any,
    createdAt: string,
    updatedAt: string,
}

export const UserSlice = createSlice({
    name: 'user',
    initialState: {        
    },
    reducers: {
        setUser: (state, action) => {
            console.log('setUser', action.payload);
            const parsedUser = {
                userId: action.payload.userId,
                usedCredits: action.payload.usedCredits,
                availableCredits: action.payload.availableCredits,
                transactions: action.payload.transactions,
                createdAt: action.payload.createdAt,
                updatedAt: action.payload.updatedAt,
            }
            return {...parsedUser};
        },
        updateUser: (state, action: PayloadAction<{
            properties: any
        }>) => {
            for(const property in action.payload.properties) {
                // @ts-ignore
                state.user[property] = action.payload.properties[property];
            }
        },
        clearUser: (state) => {
            return {};
        },
    },
});

export const userActions = UserSlice.actions;
export default UserSlice.reducer;
