import { title } from 'process';
import useColors from '../../hooks/useColors';
import smGradient from '../../theme/img/01.png';
import me from '../../theme/img/IMG_1451.png';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const About: React.FC = () => {

    const colors = useColors();
    const navigate = useNavigate();

    const [faqs, setFaqs] = useState([
        {
            title: 'What are use cases for Lazy Write?',
            content: 'Lazy Write can be used for a variety of use cases. Some of the most common use cases include: - Blog posts - Product descriptions - Social media posts - Email marketing - Website content - SEO content - And more! Lazy Write is designed to help you quickly generate content for any use case you can think of.',
            open: false
        },
        {
            title: 'Can I try for free?',
            content: 'Yes! You can register now and receive 2,000 word credits free. This will allow you to test the service and see if it is right for you.',
            open: false
        },
        {
            title: 'What is the quality of the content like?',
            content: 'Lazy Write uses a powerful generative AI model to generate content. The quality of the content is very high and is comparable to content written by a human. The content generated by Lazy Write is unique, engaging, and informative.',
            open: false
        },
        {
            title: 'How do you store my data?',
            content: 'Lazy Write takes data privacy and security very seriously. We use encryption and security measures to protect your data. We do not share your data with any third parties. Your data is stored securely on our servers and is only accessible to you.',
        }
    ]);

    const faqsColorMap = ['#8e68de', '#8265d4', '#7662ca', '#6b5ebf']

    return (
        <div className="flex flex-col justify-between h-full">
            <div>
                <img
                    src={smGradient}
                    alt="sm-gradient"
                    className="absolute w-full h-full left-0 right-0 z-[-10] m-auto opacity-25"
                />

                <div className='flex flex-wrap justify-between mx-auto gap-[36px] flex max-w-7xl justify-between p-6 lg:px-8'>
                    <div 
                        className='basis-[100%] md:basis-[60%]'
                    >
                        <h1 className="text-2xl font-bold mb-[42px]">About Us</h1>
                        <div 
                            className="p-[12px] rounded-[8px]"
                            style={{
                                background: 'linear-gradient(180deg, rgba(86, 86, 170,0.25) 0%, rgba(54, 69, 125,0.25) 100%)'
                            }}
                        >
                        <p className="pt-[8px]">
                            Lazy Write has been developed to allow you to quickly scale content about your products, services and areas of interest without sacrificing quality for time.
                        </p>
                        <p className="pt-[8px]">
                            We understand it’s challenging to start any kind of business venture but with the help of Lazy Write, you can focus your time and energy on other demanding areas of your business.
                        </p>
                        <p className="pt-[8px]">
                            By leveraging advancements in generative AI, you can cut the costs associated with hiring writers, affording you the freedom to explore spending on the development of your projects.
                        </p>

                        
                        <p className="py-[42px] text-xl font-semibold">
                            How we differ from competitors
                        </p>

                        <p>
                        Our approach allows for quick scalability of larger pieces of content. We achieve this by providing you intelligent suggestions based on other content within your project. These suggestions can then be generated within the press of a button without any additional prompts.
                        </p>

                        <p className="mt-[14px]">
                        Our pricing model focuses on charging per word as opposed to a subscription based model. This makes it more simple to quantify the value of our service. GetACopywriter.com services start from as high as £100+ per 1,000 words. Lazy Write charges £3.49 per 10,000 words. That is less than 0.01% of the cost!
                        </p>

                        <p className="mt-[14px]">
                        <span 
                            className="cursor-pointer font-medium" 
                            style={{color: colors.gradientBorderStart}}
                            onClick={() => navigate('/login?type=register')}
                        >Register now</span> and receive 2,000 word credits free.
                        </p>
                        </div>
                    </div>
                    <div className="basis-[100%] md:basis-[30%]">
                        <h1 className="text-2xl font-bold mb-[42px]">FAQs</h1>
                        <div 
                            className='border rounded-[8px]'
                            style={{
                                borderColor: colors.primaryBorder
                            }}
                        >
                            {faqs.map((faq, index) => (
                                <div>
                                    <div 
                                        className={`flex justify-between items-center p-[12px] cursor-pointer
                                        ${index === 0 ? 'rounded-t-[8px]' : ''}
                                        ${index === faqs.length - 1 && !faq.open ? 'rounded-b-[8px]' : ''}
                                        `}

                                        style={{
                                            background: faqsColorMap[index]
                                        }}
                                        onClick={() => {
                                            const _faq = faqs.find(f => f.title === faq.title);
                                            if(_faq) {
                                                _faq.open = !_faq.open;
                                                setFaqs([...faqs]);
                                            }

                                        }}
                                    >
                                        <h2 className="text-lg font-medium">
                                            {faq.title}
                                        </h2>
                                        {faq.open ? (
                                            <ChevronUpIcon 
                                                className='w-[24px] h-[24px]'
                                            />
                                        ) : (
                                            <ChevronDownIcon
                                                className='w-[24px] h-[24px]'
                                            />
                                        )}
                                    </div>
                                    {faq.open && (
                                    <div 
                                        style={{
                                            background: colors.primaryBG,
                                        }}
                                        className={`
                                            ${index === faqs.length - 1 && faq.open ? 'rounded-b-[8px]' : ''}
                                        `}
                                    >
                                        <p className="p-[12px]">
                                            {faq.content}
                                        </p>
                                    </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default About;