import PriceSlider from "../../components/PriceSlider";

const Prices: React.FC = () => {
    return (
        <div className="flex flex-col items-center justify-center w-full h-full">
            <div className="w-[90%] md:w-[75%] lg:w-[50%] mb-[36px]">
                <h1 className="text-2xl font-bold text-center text-white mb-[12px]">Prices</h1>
                <p className="pb-[12px]">
                    Our payment system is powered by stripe so you can be sure that your payment information is secure when marketing
                    purchases. We do not store any payment information on our servers and all information during the transaction is encrypted.
                    We offer free storage for all of the content you generate on our platform allowing you to safely store your content 
                    for as long as you want.
                </p>
                <PriceSlider text="Transparent payment model - simply top up your word allowance and spend as you please. Avoid unexpected overpayments." />
                
            </div>
        </div>
    )
}

export default Prices;