import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Content, ContentType, GeneratedStatus, Suggestion, Tone } from "../../globalTypes";
import { projectActions } from "../../storage/ProjectsSlice";
import { useCallback, useEffect, useRef, useState } from "react";
import store from "../../storage/store";
import GradientBorderButton from "../../components/GradientBorderButton";
import './ContentPage.css';
import LoginService from "../../auth/LoginService";
import useColors from "../../hooks/useColors";
import CreateModal from "../../components/CreateModal";
import ContentService from "../../content-service/ContentService";
import { Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import * as DOMPurify from 'dompurify';
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { userActions } from "../../storage/UserSlice";

const ContentPage: React.FC = () => {

    const navigate = useNavigate();

    const colors = useColors();
    const suggestionCards: any = useRef(Array(5))
    const suggestionCardsIcons: any = useRef(Array(5));

    const { projectID, contentID }: any = useParams();
    const project = useSelector((state: any) => {
        return state.projects[projectID] ?? null;
    });

    const content = useSelector((state: any) => {
        return state.projects[projectID]?.content[contentID] ?? null;
    });

    const user = useSelector((state: any) => state.user);

    let [tones, setTones]: [Tone[], any] = useState([
        'Absurd', 'Amused', 'Appreciative', 'Assertive', 'Concerned', 'Conversational', 'Cooperative', 'Curious',
        'Encouraging', 'Engaging', 'Fearful', 'Formal', 'Friendly', 'Horror', 'Humorous', 'Informal', 'Joyful',
        'Optimistic', 'Persuasive', 'Pessimism', 'Respectfulness', 'Sad', 'Satirical', 'Serious'
    ]);

    let [contentTypes, setContentTypes]: [ContentType[], any] = useState([
        'Advertisement', 'Article', 'Blog Post', 'Book Chapter', 'Brochure', 'Catalog',
        'Course Material', 'Email', 'Essay', 'Flyer', 'Game Guide', 'Letter',
        'Memo', 'Menu', 'News Article', 'Newsletter', 'Poem', 'Poster', 'Presentation', 'Press Release',
        'Product Description', 'Proposal', 'Recipe',  'Report', 'Research Paper', 'Review', 'Short Story',
        'Speech', 'Technical Document', 'Thesis', 'User Manual', 'Video Script', 'Website Content'
    ]);


    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [contentTone, setContentTone] = useState<Tone>(tones[0]);
    const [contentType, setContentType] = useState<ContentType>(contentTypes[0]);
    const [contentBody, setContentBody] = useState<string>('');
    const [contentWordCount, setContentWordCount] = useState(500);
    const [contentActualWordCount, setContentActualWordCount] = useState<number>(0);
    const [requestingContent, setRequestingContent] = useState<boolean>(false);
    const [contentSuggestions, setContentSuggestions] = useState<Suggestion[]>([]);
    const [requestingSuggestions, setRequestingSuggestions] = useState<boolean>(false);

    const [showGenerateModal, setShowGenerateModal] = useState<boolean>(false);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number>(-1);

    // Show sub-prompts if there is no content body upon loading
    const [showSubPrompts, setShowSubPrompts] = useState<boolean>(content?.body == null || content?.body.length === 0);
    const [promptsSubmitted, setPromptsSubmitted] = useState<boolean>(content?.body != null && content?.body.length > 0);

    // Effect to update local state when the Redux state changes
    useEffect(() => {

        if(projectID && contentID && project?.content[contentID]) {
            ContentService.addRecentlyViewed(projectID, contentID);
        }

        setTitle(content?.title ?? '');
        setDescription(content?.description ?? '');
        setContentTone(content?.tone ?? tones[0]);
        setContentType(content?.type ?? contentTypes[0]);
        setContentWordCount(content?.wordCount ?? 500);
        setContentActualWordCount(content?.finalWordCount ?? null);
        setActiveSuggestionIndex(-1);

        if(!requestingContent) {
            setContentBody(content?.body ?? '');
        }

        if(!tones.includes(content?.tone) && content?.tone) {
            setTones([content?.tone, ...tones].sort());
        }

        if(!contentTypes.includes(content?.type) && content?.type) {
            setContentTypes([content?.type, ...contentTypes].sort());
        }

        try {
            setContentSuggestions(content?.suggestions ?? []);
        } catch(error) {
            setContentSuggestions([]);
            console.log('Error parsing suggestions', error);
        }
    }, [content]);

    useEffect(() => {
        const tempPromptsSubmitted = content?.body != null && content?.body.length > 0;
        if(tempPromptsSubmitted && !requestingContent) {
            setPromptsSubmitted(tempPromptsSubmitted);
            setShowSubPrompts(false);
        }
    }, [contentBody]);

    /* This useEffect is not best practice as it updates the state of a dependency of the useEffect above,
     * which in-turn will trigger the dependency of this useEffect. We get away with it here because the
     * the dependency above will only be changed whilst there are pending suggestions, which should become empty and break
     * the re-render cycle.
     *
     * The interval also assumes that the pendingSuggestions will become empty at some point
     * for it to be cleared. There is a risk of a suggestion being stuck in a pending state
     * indefinitely which will lead to the interval running indefinitely.
     */
    useEffect(() => {
        
        const pendingSuggestionIndexes = content?.suggestions?.map((suggestion: Suggestion, index: number) => {
            return suggestion.generatedStatus === GeneratedStatus.PENDING ? index : -1;
        }).filter((index: number) => index !== -1) || [];

        // If there are pending suggestions, check every 5 seconds if they have been generated
        if(pendingSuggestionIndexes.length > 0) {

            const interval = setInterval(async() => {
                const projects = await ContentService.fetchProjects();
                const updatedSuggestions = projects[projectID].content[contentID].suggestions;
                if(pendingSuggestionIndexes.length > 0) {
                    for(let i = pendingSuggestionIndexes.length - 1; i >= 0; i--) {
                        let correspondingIndex = pendingSuggestionIndexes[i];
                        if(updatedSuggestions[correspondingIndex].generatedStatus !== GeneratedStatus.PENDING) {
                            pendingSuggestionIndexes.splice(i, 1);
                        }
                    }
                } else {
                    clearInterval(interval);
                    store.dispatch(projectActions.setProjects(projects));
                    await ContentService.refreshUser();
                }
            }, 5000);
        }

    }, [contentSuggestions]);

    const changeContentProperties = async(properties: Partial<Content>) => {
        ContentService.updateContent({
            projectID: projectID, 
            contentID: contentID, 
            properties: properties
        });
    }

    const handleDescriptionChange = (e: any) => {
        setDescription(e.target.value);
    };

    const handleDescriptionBlur = () => {
        changeContentProperties({
            description: description
        });
    };

    const handleTitleChange = (e: any) => {
        setTitle(e.target.value);
    }

    const handleTitleBlur = () => {
        changeContentProperties({
            title: title
        });
    }

    const handleToneChange = (e: any) => {
        setContentTone(e.target.value);
        changeContentProperties({
            tone: e.target.value
        });
    }

    const handleTypeChange = (e: any) => {
        setContentType(e.target.value);
        changeContentProperties({
            type: e.target.value
        });
    }

    const handleWordCountChange = (val: any, updateServer: boolean) => {
        if(Number.isInteger(parseInt(val)) === false){
            val = 0
        };
        setContentWordCount(parseInt(val));
        if(updateServer) {
            if(parseInt(val) < 49) {
                val = 50;
            }
            if(parseInt(val) > 10000) {
                val = 10000;
            }
            setContentWordCount(parseInt(val));
            changeContentProperties({
                wordCount: parseInt(val)
            });
        }
    }

    const sendMessage = async() => {

        if(contentWordCount > user?.availableCredits) {
            alert('Not enough word credits to generate request, please lower the word count or top up your credits.');
            return;
        }

        setRequestingContent(true);
        setPromptsSubmitted(true);
        setShowSubPrompts(false);

        const userSession = await LoginService.getUserSession();
        const URL = 'https://vkc3d2axja6cmcinr3cqjodo6u0tpfau.lambda-url.us-east-2.on.aws/';
        const response = await fetch(URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': userSession.getIdToken().getJwtToken(),
            },
            body: JSON.stringify({
                projectID: projectID,
                contentID: contentID,
                projectTitle: project.title,
                title: title,
                description: description,
                tone: contentTone,
                type: contentType,
                wordCount: contentWordCount,
            }),
        });

        // The response body is a stream
        const reader = response?.body?.getReader();

        // Creating a TextDecoder to decode the Uint8Array to string
        const decoder = new TextDecoder();

        // Function to read the stream
        async function read() {
            let result;
            let tempContentBody = '';
            do {
                result = await reader?.read();
                if(result == null) {
                    console.log('Result is null');
                    break;
                }
                if (result?.done) {
                    console.log('Stream completed');
                    break;
                }
                // Convert Uint8Array to string
                const chunk = decoder.decode(result?.value, { stream: true });
                setContentBody(prev => prev + chunk);
                tempContentBody += chunk;
            } while (!result?.done);

            store.dispatch(projectActions.updateContent({
                projectID: projectID, 
                contentID: contentID, 
                properties: { body: tempContentBody}
            }));
        }

        // Start reading the stream
        read();

        setRequestingSuggestions(true);
        const suggestions = await ContentService.requestSuggestions(
            projectID, 
            contentID, 
            project.title, 
            title, 
            description, 
            contentTone, 
            contentType
        );

        await ContentService.refreshUser();

        setRequestingSuggestions(false);
        setContentSuggestions(suggestions);
        setRequestingContent(false);

        store.dispatch(projectActions.updateContent({
            projectID: projectID, 
            contentID: contentID,
            properties: { suggestions: JSON.parse(suggestions)}
        }));
    }

    const generateSuggestedContent = useCallback(async (title: string, description?: string) => {
        const activeSuggestion = contentSuggestions[activeSuggestionIndex];

        if(user.availableCredits < activeSuggestion.wordCount) {
            alert('Not enough word credits to generate request, please top up your credits.');
            suggestionCards.current[activeSuggestionIndex].style.background = colors.secondaryBG;
            suggestionCardsIcons.current[activeSuggestionIndex].innerHTML = `
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                    </svg>
                </div>
            `;
            setActiveSuggestionIndex(-1);
            return false;
        }

        // Update the status of the suggestion to pending
        store.dispatch(projectActions.updateContentSuggestion({
            projectID,
            contentID,
            suggestionIndex: activeSuggestionIndex,
            property: 'generatedStatus',
            value: GeneratedStatus.PENDING,
        }));

        // Request the suggestion
        const newContentID = await ContentService.requestSuggestion(
            projectID,
            contentID,
            activeSuggestionIndex,
            project.title,
            activeSuggestion.title,
            activeSuggestion.description,
            activeSuggestion.tone,
            activeSuggestion.type,
            activeSuggestion.wordCount !== 0 ? activeSuggestion.wordCount : 500,
        );

        // Update the status of the suggestion to success
        store.dispatch(projectActions.updateContentSuggestion({
            projectID,
            contentID,
            suggestionIndex: activeSuggestionIndex,
            property: 'generatedStatus',
            value: GeneratedStatus.SUCCESS,
        }));
    
        // Update the generatedID of the suggestion
        store.dispatch(projectActions.updateContentSuggestion({
            projectID,
            contentID,
            suggestionIndex: activeSuggestionIndex,
            property: 'generatedID',
            value: newContentID,
        }));

        return true;
    }, [activeSuggestionIndex, contentID]);

    const toggleSuggestionActive = (i: number) => {
        suggestionCards.current[i].style.background = colors.gradientBorderStart;
        // lockedIcon.current[i].style.display = 'none';
        // unlockedIcon.current[i].style.display = 'flex';
        suggestionCardsIcons.current[i].innerHTML = `
            <div class="flex items-center justify-center">
                <p class="pr-[4px]">
                    Unlock
                </p>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5V6.75a4.5 4.5 0 1 1 9 0v3.75M3.75 21.75h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H3.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                </svg>
            </div>
        `;
        setActiveSuggestionIndex(i);
        setShowGenerateModal(true);
    }

    const onCancelSuggestion = useCallback(async() => {
        setShowGenerateModal(false);
        suggestionCards.current[activeSuggestionIndex].style.background = colors.secondaryBG;
        suggestionCardsIcons.current[activeSuggestionIndex].innerHTML = `
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                </svg>
            </div>
        `;
        setActiveSuggestionIndex(-1);
    }, [showGenerateModal]);

    const contentExistsInProject = (contentID: string | null) => {
        if(project == null || contentID == null) return false;
        return project?.content[contentID] != null;
    };

    return (
        <div 
            id="content-container"
            className="px-4 py-2 sm:px-2 py-2 md:px-4 py-4 lg:px-40 py-4"
        >
            <CreateModal
                isOpen={showGenerateModal} 
                toggleModal={() => setShowGenerateModal(!showGenerateModal)} 
                onSubmit={generateSuggestedContent}
                presetTitle={contentSuggestions?.[activeSuggestionIndex]?.title ?? ''}
                presetDescription={contentSuggestions?.[activeSuggestionIndex]?.description ?? ''}
                type="suggestion"
                onCancel={onCancelSuggestion}
            />
            {content ? (
            <>
                <div className="content-bg" />
                    <div 
                        id="content-prompts" 
                        className="relative pt-[12px] px-[12px] rounded-[8px] mb-8"
                        style={{
                            position: 'relative',
                            background: 'rgb(10, 10, 29, 0.75)',
                            border: `2px solid ${colors.primaryBorder}`,
                        }}
                    >
                        <div id="content-header" className="flex-col items-center mb-[12px]">
                            <p
                                id="content-title-label"
                                className="text-gray-500 text-sm mb-[6px]"
                            >
                                Title
                            </p>
                            <div className="relative mb-[12px]">
                                <input
                                    id="title"
                                    className="flex justify-start font-medium text-3xl mr-2 w-full"
                                    style={{
                                        background: "transparent",
                                    }}
                                    value={title}
                                    onChange={handleTitleChange}
                                    onBlur={handleTitleBlur}
                                />
                                <div className="absolute right-0 top-[4px] opacity-25">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <Transition 
                            show={showSubPrompts}
                            enter="transition-all duration-1000"
                            enterFrom="opacity-0 h-0"
                            enterTo="opacity-100 h-[240px]"
                            leave="transition-all duration-1000"
                            leaveFrom="opacity-100 h-[240px]"
                            leaveTo="opacity-0 h-0"
                        >
                            <div id="content-brief" className="mb-[12px]">
                                {promptsSubmitted ? (
                                    <>
                                    <div id="content-brief-label" className="text-gray-500 text-sm mb-[12px]">
                                        Short Brief
                                        <span className="text-gray-600 text-xs ml-1">
                                            (250 char max)
                                        </span>
                                    </div>
                                    <p className="text-white text-md mb-[12px]">
                                        {description == null || description === '' ? <span style={{color: colors.secondaryText}} className="italic">Empty...</span> : description}
                                    </p>
                                    <div className="flex justify-start gap-[24px]">
                                        <div className="flex flex-col justify-end">
                                            <p 
                                                id="content-tone-label" 
                                                className={`text-sm mb-1`}
                                                style={{color: colors.secondaryText}}
                                            >
                                                Content Type
                                            </p>
                                            <p className="text-white text-md mb-1">
                                                {contentType}
                                            </p>
                                        </div>
                                        <div className="flex flex-col justify-end">
                                            <p 
                                                id="content-tone-label" 
                                                className={`text-sm mb-1`}
                                                style={{color: colors.secondaryText}}
                                            >
                                                Tone
                                            </p>
                                            <p className="text-white text-md mb-1">
                                                {contentTone}
                                            </p>
                                        </div>
                                        <div className="flex flex-col justify-end">
                                            <p 
                                                id="project-wordCount" 
                                                className="text-sm mb-1"
                                                style={{color: colors.secondaryText}}
                                            >
                                                Word Count
                                            </p>
                                            <p className="text-white text-md mb-1">
                                                {contentWordCount}
                                            </p>
                                        </div>
                                        {contentActualWordCount != null && (
                                        <div className="flex flex-col justify-end">
                                            <div className="flex items-center gap-[8px]">
                                                <p 
                                                    id="project-actualWordCount" 
                                                    className="text-sm mb-1"
                                                    style={{color: colors.secondaryText}}
                                                >
                                                    Final Word Count
                                                </p>
                                            </div>
                                            <p className="text-white text-md mb-1">
                                                {contentActualWordCount}
                                            </p>
                                        </div>
                                        )}
                                    </div>
                                    
                                    </>
                                ) : (
                                    <>
                                    <div id="content-brief-label" className="text-gray-500 text-sm mb-1">
                                        Short Brief
                                        <span className="text-gray-600 text-xs ml-1">
                                            (250 char max)
                                        </span>
                                    </div>
                                    <textarea 
                                        id="content-brief-textarea" 
                                        className="p-1 w-full h-46"
                                        style={{
                                            background: 'transparent', 
                                            border: `1px solid ${colors.primaryBorder}`,
                                            borderRadius: 4,
                                        }}
                                        value={description}
                                        onChange={handleDescriptionChange}
                                        onBlur={handleDescriptionBlur}
                                        maxLength={250}
                                        // disabled={contentBody.length > 0}
                                    />
                                    <div id="content-options" className="flex flex-row justify-between mb-[12px] shrink">
                                        <div id="content-type" className="flex flex-col grow mr-6">
                                            <p 
                                                id="content-tone-label" 
                                                className={`text-sm mb-1`}
                                                style={{color: colors.secondaryText}}
                                            >
                                                Content Type
                                            </p>
                                            <select
                                                id="content-type-select"
                                                className={`p-2 h-[55px] rounded-[4px] border-[1px]`}
                                                style={{background: colors.secondaryBG, borderColor: colors.primaryBorder}}
                                                value={contentType}
                                                onChange={handleTypeChange}
                                                // disabled={contentBody.length > 0}
                                            >
                                                {contentTypes.map((type: ContentType) => (
                                                    <option key={type} value={type}>{type}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div id="content-tone" className="flex flex-col grow">
                                            <p 
                                                id="content-tone-label" 
                                                className={`text-sm mb-1`}
                                                style={{color: colors.secondaryText}}
                                            >
                                                Tone
                                            </p>
                                            <select
                                                id="content-tone-select"
                                                className={`p-2 h-[55px] rounded-[4px] border-[1px]`}
                                                style={{background: colors.secondaryBG, borderColor: colors.primaryBorder}}
                                                value={contentTone}
                                                onChange={handleToneChange}
                                                // disabled={contentBody.length > 0}
                                            >
                                                {tones.map((tone: Tone) => (
                                                    <option key={tone} value={tone}>{tone}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div id="content-generate" className="flex justify-between items-end">
                                        <div>
                                            <p 
                                                id="project-wordCount" 
                                                className="text-sm mb-1"
                                                style={{color: colors.secondaryText}}
                                            >
                                                Word Count
                                            </p>
                                            <div className="font-medium">
                                                <input 
                                                    type="text" 
                                                    inputMode="numeric"
                                                    onChange={(e) => handleWordCountChange(e.target.value, false)}
                                                    onBlur={(e) => handleWordCountChange(e.target.value, true)}
                                                    className="w-[100px] p-1 text-center rounded-[8px] input-bg"
                                                    value={contentWordCount}
                                                />
                                            </div>
                                        </div>
                                        <GradientBorderButton
                                            id="content-generate-button"
                                            onClick={() => {
                                                sendMessage();
                                            }}
                                            disabled={contentBody.length > 0}
                                        >
                                            Generate
                                        </GradientBorderButton>
                                    </div>
                                    </>
                            )}
                        </div>
                        </Transition>
                        <div 
                            className="flex justify-center"
                        >
                            <div 
                                className="px-[16px] rounded-t-[8px] rounded cursor-pointer"
                                style={{
                                    background: colors.secondaryBG,
                                }}
                                onClick={() => setShowSubPrompts(!showSubPrompts)}
                            >
                                {showSubPrompts ? (
                                    <ChevronDownIcon 
                                        className="h-6 w-6 text-gray-400 cursor-pointer"
                                    />
                                ) : (
                                    <ChevronUpIcon 
                                        className="h-6 w-6 text-gray-400 cursor-pointer"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div id="content-output" className="flex flex-col shrink mb-6">
                        <div id="content-output-label" className="text-gray-500 text-sm mb-1">Output</div>
                        <div 
                            id="content-output-textarea" 
                            className="overflow-scroll p-[12px] w-full h-full min-h-[200px]"
                            style={{
                                background: colors.primaryBG,
                                borderRadius: 8,
                                border: `2px solid ${colors.primaryBorder}`,
                            }}
                        >
                            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contentBody) }} />
                        </div>
                    </div>
                    <p
                        id="suggested-label"
                        className="text-[16px] font-bold py-[12px]"
                        style={{color: colors.secondaryText}}
                    >
                        Suggested Content
                    </p>
                    <div 
                        id="content-suggestions" 
                        className="flex flex-nowrap overflow-scroll min-h-[100px] max-h-[600px] rounded p-[8px]"
                        style={{
                            background: 'rgb(10, 10, 29, 0.75)',
                            border: `2px solid ${colors.primaryBorder}`,
                        }}
                    >
                        {contentSuggestions ? (
                            contentSuggestions.map((suggestion: Suggestion, index: number) => {
                                let icon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                                </svg>`;

                                let suggestionTitle = '';
                                let suggestionTone = '';
                                let suggestionType = '';
                                let suggestionWordCount = 500;
                                const wasGeneratedThenDeleted = suggestion.generatedStatus === GeneratedStatus.SUCCESS && !contentExistsInProject(suggestion.generatedID ?? null);
                                if(wasGeneratedThenDeleted || suggestion.generatedStatus === GeneratedStatus.PENDING) {
                                    suggestionTitle = suggestion.title;
                                    suggestionTone = suggestion.type;
                                    suggestionType = suggestion.tone;
                                    suggestionWordCount = suggestion.wordCount !== 0 ? suggestion.wordCount : 500;
                                } else {
                                    suggestionTitle = suggestion.generatedID ? project.content[suggestion.generatedID as string]?.title : suggestion.title;
                                    suggestionTone = suggestion.generatedID ? project.content[suggestion.generatedID as string]?.type : suggestion.tone;
                                    suggestionType = suggestion.generatedID ? project.content[suggestion.generatedID as string]?.tone : suggestion.type;

                                    if(suggestion.generatedID) {
                                        suggestionWordCount = project.content[suggestion.generatedID as string]?.finalWordCount;
                                    } else {
                                        suggestionWordCount = suggestion.wordCount !== 0  ? suggestion.wordCount : 500;
                                    }
                                }
                                switch(suggestion.generatedStatus) {
                                    case GeneratedStatus.PENDING:
                                        icon = `
                                            <div class="flex items-center justify-center">
                                                <p class="pr-[4px]">
                                                    Unlocking
                                                </p>
                                                <div
                                                    class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                                                    role="status"
                                                />
                                            </div>
                                        `;
                                        break;
                                    case GeneratedStatus.SUCCESS:
                                        if(wasGeneratedThenDeleted) {
                                           icon =   `<div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                                                        </svg>
                                                    </div>`
                                        } else {
                                            icon = `<p className="pr-[4px]">Generated</p>`;
                                        }
                                        break;
                                    default:
                                        icon = `
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                                                </svg>
                                            </div>
                                        `;
                                        break;
                                }
                                return (
                                    <div
                                        key={index}
                                        ref={(el) => el ? suggestionCards.current[index] = el : null}
                                        className={`
                                            flex flex-col justify-between
                                            shrink-0 grow-1 
                                            basis-[40%] md:basis-[25%] lg:basis-[19.1%] 
                                            p-[8px]
                                            min-h-[200px]
                                            cursor-pointer
                                            ${index === contentSuggestions.length - 1 ? '' : 'mr-[8px]'}
                                        `}
                                        style={{
                                            background: (suggestion.generatedStatus === GeneratedStatus.SUCCESS && contentExistsInProject(suggestion.generatedID ?? null)) || suggestion.generatedStatus === GeneratedStatus.PENDING ? colors.gradientBorderStart : colors.secondaryBG,
                                            border: `1px solid ${colors.primaryBorder}`,
                                            borderRadius: 4,
                                        }}
                                        onMouseEnter={() => {
                                            if((suggestion.generatedStatus === GeneratedStatus.SUCCESS && !wasGeneratedThenDeleted) || suggestion.generatedStatus === GeneratedStatus.PENDING) return;
                                            suggestionCards.current[index].style.background = colors.gradientBorderStart;
                                            suggestionCardsIcons.current[index].innerHTML = `
                                                <div class="flex items-center justify-center">
                                                    <p class="pr-[4px]">
                                                        Unlock
                                                    </p>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5V6.75a4.5 4.5 0 1 1 9 0v3.75M3.75 21.75h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H3.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                                                    </svg>
                                                </div>
                                            `;
                                        }}
                                        onMouseLeave={() => {
                                            if((suggestion.generatedStatus === GeneratedStatus.SUCCESS && !wasGeneratedThenDeleted) || suggestion.generatedStatus === GeneratedStatus.PENDING) return;
                                            if(activeSuggestionIndex !== index) { // prevent the override onclick event below
                                                suggestionCards.current[index].style.background = colors.secondaryBG;
                                                suggestionCardsIcons.current[index].innerHTML = `
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                                                        </svg>
                                                    </div>
                                                `;
                                            }
                                        }}
                                        onClick={() => {
                                            if(suggestion.generatedStatus === GeneratedStatus.PENDING) return;
                                            if(suggestion.generatedStatus === GeneratedStatus.SUCCESS && !wasGeneratedThenDeleted) {
                                                window.scrollTo(0, 0);
                                                document.getElementById('content-container')?.scrollTo(0,0);
                                                navigate(`/app/my-content/projects/${projectID}/${suggestion.generatedID}`);
                                            } else {
                                                toggleSuggestionActive(index);
                                            }
                                        }}
                                    >
                                        <div>
                                            <p className="font-bold text-[16px] mb-[8px]">{suggestionTitle}</p>
                                            <p className="text-[14px]">{suggestionTone} {suggestionType}</p>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <p className="p-2 bg-[#0E0C15] max-w-max rounded-[4px] text-[12px]">{suggestionWordCount}</p>
                                            <div 
                                                ref={(el) => el ? suggestionCardsIcons.current[index] = el : null}
                                                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(icon)}} 
                                            />
                                        </div>
                                    </div>
                                );
                            })
                        ) : null}
                        {requestingSuggestions ? (
                            <div className="p-2">
                                Requesting suggestions...
                            </div>
                        ) : null}
                    </div>
                </>
            ) : (
                <div>Content not found</div>
            )}
        </div>
    );

}

export default ContentPage;
